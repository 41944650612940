.products-container {
    max-width: 1076px;
    margin: 0 auto;
    padding: 5vh 20px 100px;
    position: relative;
    z-index: 1;
    min-height: calc(100vh - 60px);

    @media (min-width: 1500px) {
        max-width: 1428px;
    }
    @media (max-width: 1199px) {
        max-width: 724px;
    }
    @media (max-width: 767px){
        max-width: 372px;
    }
}
.new-products-container{
    max-width: 1350px;
    margin: 0 auto;
    padding: 5vh 20px 100px;
    position: relative;
    z-index: 1;
    min-height: calc(100vh - 60px);

    @media (max-width: 1199px) {
        max-width: 992px;
    }
    @media (max-width: 991px) {
        max-width: 724px;
    }
    @media (max-width: 767px) {
        max-width: 372px;
    }
    //.products-container{
    //
    //    @media (max-width: 767px){
    //        max-width: 500px;
    //    }
    //}
    //.products-container-wrapper{
    //    max-width: none;
    //    padding: 0;
    //    position: static;
    //}
}
.products-content{
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .no-results{
        grid-column: span 3;
    }
}
.new-products-content{
    padding-left: 24px;
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media (max-width: 991px) {
        padding-left: 0;
    }
}

.oneItem {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #eaebf4;
    flex: 0 0 calc(33.333% - 13.34px);

    &:not(.new-products-content .oneItem, .products-container-related .oneItem){
        @media (min-width: 1500px) {
            flex: 0 0 calc(25% - 15px);
        }
    }
    @media (max-width: 1199px){
        flex: 0 0 calc(50% - 10px);
    }
    @media (max-width: 767px){
        flex: 0 0 100%;
    }

    .imageBox {
        width: 100%;
        height: 186px;
        background: #F4F5F8 0 0 no-repeat padding-box;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
.detailsBox {
    background: #FFFFFF 0 0 no-repeat padding-box;
    color: #000000;
    padding: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: left;
    height: calc(100% - 186px);
    min-height: 200px;

    @media (max-width: 767px) {
        height: auto;
    }
    .title {
        font-size: 18px;
        color: #000000;
        font-weight: 600;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 1.389;
        margin-bottom: 12px;
    }

    .description {
        font-size: 14px;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 1.429;
    }

    .priceBox {
        margin: 10px 0;

        .price {
            padding-right: 10px;
            line-height: 1.3;
            white-space: nowrap;
            font-weight: 600;
            font-size: 16px;
        }

        .old_price {
            opacity: .6;
            margin-right: 4px;
            font-size: 14px;
        }
    }

    .footer {
        margin-top: auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        .btn{
            padding: 0.393em 1em;
            white-space: nowrap;
            margin-left: auto;
        }
    }
}
.inStock {
    color: #45C9A5;
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-bottom: 14px;

    img{
        margin-right: 6px;
    }
    &.unavailable {
        filter: grayscale(1);
        opacity: .6;
    }
}
.product-single-container{
    display: flex;
    align-items: flex-start;
    max-width: 1340px;
    margin: 0 auto;
    padding: 5vh 20px 50px;

    @media (max-width: 1023px) {
        flex-direction: column;
        max-width: 714px;
    }
}
.product-single-left{
    width: 662px;

    @media (max-width: 1299px){
        width: 50%;
    }
    @media (max-width: 1023px){
        width: 100%;
        max-width: none;
    }
}
.product-single-slick-top {
    border: 1px solid #D6D7D9;
    border-radius: 10px;
    height: 374px;
    overflow: hidden;

    @media (max-width: 1299px){
        height: 27.28vw;
    }
    @media (max-width: 1023px){
        height: calc(56vw - 20px);
        max-height: 380px;
    }
    .slick-list, .slick-track{
        height: 100%;
    }
    .sliderItem {

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 10px;
        }
    }
}
.product-single-slick-bottom {
    padding: 0 30px;
    margin: 0 auto;
    width: 100%;

    .sliderItem {
        height: 60px;
        margin: 10px 7px 6px;
        transition: transform .2s;

        @media (max-width: 767px){
            height: 40px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 3px;
        }
        &.slick-current{
            transform: scale(1.17);
        }
    }
    .slick-list{
        padding: 0 7px;
    }
    .slick-prev, .slick-next{
        width: 32px;
        height: 32px;
        opacity: 1;
        border-radius: 50%;
        color: inherit;
        z-index: 1;

        &:before{
            border: solid black;
            border-width: 0 2px 2px 0;
            content: "";
            display: inline-block;
            padding: 6px;
            border-radius: 0;
            background-color: transparent;
            color: inherit;
        }
    }
    .slick-next {
        right: 0;

        &:before{
            margin: 0 4px 0 0;
            transform: rotate(-45deg);
            border-color: inherit;
            border-width: 0 2px 2px 0;
            padding: 6px;
        }
    }
    .slick-prev {
        left: 0;

        &:before{
            margin: 0 0 0 4px;
            transform: rotate(135deg);
            border-color: inherit;
            border-width: 0 2px 2px 0;
            padding: 6px;
        }
    }
}
.video-wrapper {
    width: 100%;
    text-align: center;
    padding: 6px 0;

    .video-btn {
        padding: 10px 18px 10px 50px;
        border: 1px solid #D6D7D9;
        border-radius: 3px;
        background-color: #F8F8F8;
        display: inline-block;
        position: relative;
        font-size: 16px;
        color: #0A0A0A;

        svg {
            position: absolute;
            left: 19px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.product-single-right{
    width: calc(100% - 672px);
    padding-left: 40px;

    @media (max-width: 1299px){
        width: 50%;
        padding-left: 30px;
    }
    @media (max-width: 1023px){
        width: 100%;
        max-width: none;
        padding-left: 0;
        margin-top: 30px;
    }

    h1{
        font-size: 26px;
        font-weight: 600;
        margin-bottom: 20px;

        @media (max-width: 767px){
            font-size: 22px;
        }
    }
}

.product-single-price{
    margin-bottom: 20px;

    span{
        font-size: 18px;
        color: #888;
    }
    del{
        color: #888;
    }
    strong{
        font-weight: 600;
        font-size: 20px;
    }
}
.product-single-description{
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 16px;
}
.promo-video {
    width: 100%;
    height: 100%;
}
.products-container-related{
    max-width: 1076px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    padding: 50px 20px 70px;
    border-top: solid 1px #cecece;

    @media (max-width: 1199px) {
        max-width: 724px;
    }
    @media (max-width: 767px){
        max-width: 372px;
        margin-top: 30px;
    }
}
.similarProducts {

    h2 {
        font-size: 24px;
        margin-bottom: 30px;
        text-align: center;
    }
}

.new-pagination{
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;

    li{
        margin: 0 1px;
        height: 26px;

        a, span{
            color: #212121;
            border-radius: 4px;
            width: 30px;
            height: 26px;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            &:hover{
                text-decoration: none;
                background-color: #F4F4F4;
            }
        }

        &.new-next, &.new-prev{
            margin-left: 20px;
            margin-right: 0;

            @media (max-width: 767px){
                margin-left: 10px;
            }
            a{
                border: 1px solid #b1b1b1;
                padding: 5px 10px;
                border-radius: 4px;
                width: 30px;
                height: 26px;
                position: relative;

                &:after, &:before{
                    background-color: #6e777b;
                    content: "";
                    display: inline-block;
                    height: 2px;
                    left: 10px;
                    position: absolute;
                    top: 8px;
                    transform: rotate(40deg);
                    transition: transform 0.3s;
                    width: 8px;
                }
                &:before{
                    transform: rotate(-40deg);
                    top: 13px;
                }
            }
        }
        &.new-prev{
            margin-left: 0;
            margin-right: 20px;
            transform: rotate(180deg);

            @media (max-width: 767px){
                margin-right: 10px;
            }
        }
        &.active{

            a, span{
                background-color: #1096E1;
                color: #ffffff;
            }
        }
    }
}



