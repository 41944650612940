
.new-filter-head{
    margin-bottom: 30px;

    @media (max-width: 991px) {
        margin-bottom: 25px;
        align-items: flex-start;
    }
    h1{
        font-weight: 600;
        font-size: 24px;

        @media (max-width: 991px){
            width: 100%;
            margin-bottom: 15px;
            font-size: 20px;
        }
    }
}
.new-filter-sort{
    max-width: 70%;

    @media (max-width: 767px){
        max-width: 100%;
        width: 100%;
        margin-bottom: 10px;
    }
    .dropdown-toggle{
        border: 1px solid #DDE2E4;
        border-radius: 2px;
        font-size: 14px;
        letter-spacing: -0.006em;
        padding: 5px 8px 5px 10px;
        width: 100%;
        display: inline-flex;
        align-items: center;

        &:after{
            display: none;
        }
        &[aria-expanded="true"]{

            .sort-arrow:before{
                transform: rotate(40deg);
            }
            .sort-arrow:after{
                transform: rotate(-40deg);
            }
        }
        i{
            font-style: normal;
            color: #9AA6AC;
            margin-right: 6px;
        }
        strong{
            font-weight: 400;
            color: #252C32;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
            margin-right: 6px
        }
        .sort-arrow{
            margin-left: auto;
        }
    }

    .dropdown-menu{
        margin: 0.4rem 0 0;

        .dropdown-item{
            position: relative;
            color: #252C32;
            font-size: 12px;
            margin: 2px 0;
            padding: 0.25rem 1.9rem;

            &:before{
                display: inline-block;
                position: absolute;
                left: 13px;
                top: 6px;
                transform: rotate(45deg);
                height: 0.8em;
                width: 0.4em;
                border-bottom: 0.12em solid #1096E1;
                border-right: 0.12em solid #1096E1;
            }

            &.active{
                background-color: transparent;

                &:before{
                    content: "";
                }
            }
            &:active{
                background-color: #ccd3d9;
            }
        }
    }
}
.sort-arrow {
    display: inline-flex;
    width: 14px;
    height: 14px;
    align-items: center;
    justify-content: center;
    transform-origin: center;
    position: relative;
    margin-left: 6px;

    &:after, &:before{
        content: "";
        display: inline-block;
        height: 1.7px;
        left: 0;
        position: absolute;
        top: 7px;
        width: 8px;
        transform: rotate(40deg);
        background-color: #9AA6AC;
        transition: transform 0.3s;
    }
    &:before{
        transform: rotate(-40deg);
        left: 5px;
    }
}
.open-new-filter{
    border: 1px solid #DDE2E4;
    border-radius: 2px!important;
    font-size: 14px;
    letter-spacing: 0!important;
    padding: 5px 8px 5px 10px!important;
    color: #9AA6AC;

    &[aria-expanded="true"]{

        .sort-arrow:before{
            transform: rotate(40deg);
        }
        .sort-arrow:after{
            transform: rotate(-40deg);
        }
    }
}
.new-filter{
    width: 250px;
    flex: 0 0 250px;
    font-size: 14px;
    color: #252C32;

    @media (min-width: 992px) {
        display: block!important;
    }
    @media (max-width: 1350px) {
        width: 244px;
        flex: 0 0 244px;
    }
    @media (max-width: 991px){
        position: absolute;
        right: 5px;
        top: 104px;
        z-index: 3;
        background-color: #ffffff;
        border: 1px solid #DDE1E4;
        padding: 0 15px;
        border-radius: 0.25rem;
    }
    @media (max-width: 767px){
        top: 145px;
    }
    .form-control{
        border: 1px solid #DDE2E4;
        border-radius: 2px;
        font-size: 14px;

        &::placeholder{
            color: #9AA6AC;
        }
        &:focus{
            outline: none;
            box-shadow: none;
            border-color: #ced2d3;
        }
    }

    [aria-expanded="true"]{

        .sort-arrow:before{
            transform: rotate(40deg);
        }
        .sort-arrow:after{
            transform: rotate(-40deg);
        }
    }

    .custom-switch{
        margin-right: -8px;
    }
    .custom-control-input:checked ~ .custom-control-label::before{
        background-color: #1096E1;
    }
}
.new-filter-btn{
    padding: 9px 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    color: #252C32;
    line-height: 1.429;
}
.new-filter-search{
    margin-bottom: 20px;

    @media (max-width: 991px) {
        margin-top: 20px;
    }
    .btn{
        position: absolute;
        right: 5px;
        top: 0;
        color: #737373;
        padding: 3px;
    }
    .form-control{
        padding-right: 35px;
        height: 36px;
    }
}
.new-filter-price{
    gap: 12px;
}
.new-filter-label{
    font-weight: 600;
    font-size: 14px;
    color: #252C32;
    margin-bottom: 15px;
    display: block;
}
.new-filter-collapse{
    padding: 10px 0;

    .custom-checkbox-glogal{
        margin-bottom: 1em;
    }
    .custom-checkbox-glogal input + label{
        font-size: 14px;
        color: #252C32;
    }
    .custom-checkbox-glogal input + label::before{
        width: 1.2em;
        height: 1.2em;
        border-radius: 2px;
        background-color: #F6F8F9;
    }
    .custom-checkbox-glogal input:checked + label::before{
        background-color: #1096E1;
        border-color: #1096E1;
    }
    .custom-checkbox-glogal input + label::after{
        border-color: #ffffff;
        border-width: 0.12em;
        height: 0.34em;
        width: 0.8em;
        left: 0.23em;
    }
}
.new-clean-filter{
    padding: 0;
    font-size: 14px;
}


//.new-pagination{
//    display: flex;
//    align-items: center;
//    list-style: none;
//    padding: 0;
//
//    li{
//        margin: 0 1px;
//        height: 26px;
//
//        a, span{
//            color: #212121;
//            border-radius: 4px;
//            width: 30px;
//            height: 26px;
//            display: inline-flex;
//            align-items: center;
//            justify-content: center;
//
//            &:hover{
//                text-decoration: none;
//                background-color: #F4F4F4;
//            }
//        }
//
//        &.new-next, &.new-prev{
//            margin-left: 20px;
//            margin-right: 0;
//
//            @media (max-width: 767px){
//                margin-left: 10px;
//            }
//            a{
//                border: 1px solid #b1b1b1;
//                padding: 5px 10px;
//                border-radius: 4px;
//                width: 30px;
//                height: 26px;
//                position: relative;
//
//                &:after, &:before{
//                    background-color: #6e777b;
//                    content: "";
//                    display: inline-block;
//                    height: 2px;
//                    left: 10px;
//                    position: absolute;
//                    top: 8px;
//                    transform: rotate(40deg);
//                    transition: transform 0.3s;
//                    width: 8px;
//                }
//                &:before{
//                    transform: rotate(-40deg);
//                    top: 13px;
//                }
//            }
//        }
//        &.new-prev{
//            margin-left: 0;
//            margin-right: 20px;
//            transform: rotate(180deg);
//
//            @media (max-width: 767px){
//                margin-right: 10px;
//            }
//        }
//        &.active{
//
//            a, span{
//                background-color: #1096E1;
//                color: #ffffff;
//            }
//        }
//    }
//}
