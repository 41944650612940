.faq-section {
    background-color: #F4F5F8;
    padding-top: 40px;
    padding-bottom: 70px;
    min-height: calc(100vh - 385px);

    h1{
        font-size: 25px;
        margin-bottom: 26px;
    }
}

.faq-accordion {
    max-width: 740px;
    margin: 0 auto;
    padding: 0 20px;

    .card {
        margin-bottom: 15px;
        border: 1px solid #e6e6e6;
        border-radius: 11px !important;
        box-shadow: 0 3px 6px #00000012;
    }

    .card-body {
        padding: 16px 19px 16px 24px;

        p{
            font-size: 15px;
        }

        @media (max-width: 767px) {
            font-size: 14px;
            padding: 12px 12px 16px 15px;
        }
    }

    .card-header {
        background-color: transparent;
        padding: 16px 19px 16px 24px;
        border: none !important;
        margin-bottom: 0!important;

        @media (max-width: 767px) {
            padding: 12px 12px 16px 15px;
        }

        .btn {
            text-align: left;
            position: relative;
            text-transform: inherit;
            padding: 0;
            white-space: normal;
            background-color: #fff;
            border-radius: 0;

            &:after {
                content: "";
                width: 22px;
                height: 22px;
                background-size: 100%;
                display: inline-block;
                position: absolute;
                right: 0;
                top: 50%;
                margin-top: -11px;
                background-repeat: no-repeat;
            }

            &[aria-expanded="true"] {
                color: var(--btn1-background-color);;
            }

            &[aria-expanded="true"]:after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='Layer_1' data-name='Layer 1' viewBox='0 0 23 23'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23244199;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eminus%3C/title%3E%3Cg id='Group_8042' data-name='Group 8042'%3E%3Cg id='Group_8043' data-name='Group 8043'%3E%3Cg id='add'%3E%3Cg id='Group_8040' data-name='Group 8040'%3E%3Cg id='Group_8039' data-name='Group 8039'%3E%3Cpath id='Path_8670' data-name='Path 8670' class='cls-1' d='M11.5,0A11.5,11.5,0,1,0,23,11.5,11.5,11.5,0,0,0,11.5,0Zm0,22.05A10.55,10.55,0,1,1,22.05,11.5,10.55,10.55,0,0,1,11.5,22.05Z' transform='translate(0 0)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3Cpath id='Path_8672' data-name='Path 8672' class='cls-1' d='M6,11.5H17a.5.5,0,0,1,.5.5.5.5,0,0,1-.5.5H6a.5.5,0,0,1-.5-.5A.51.51,0,0,1,6,11.5Z' transform='translate(0 0)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            }

            &[aria-expanded="false"]:after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23.008' height='23.008' viewBox='0 0 23.008 23.008'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23767676;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(174.961 -91.039)'%3E%3Cg transform='translate(-174.961 91.039)'%3E%3Cpath class='a' d='M11.5,0a11.5,11.5,0,1,0,11.5,11.5A11.517,11.517,0,0,0,11.5,0Zm0,22.049A10.545,10.545,0,1,1,22.049,11.5,10.557,10.557,0,0,1,11.5,22.049Z'/%3E%3Cpath class='a' d='M119.608,112.9h-5.752v-5.752a.479.479,0,1,0-.959,0V112.9h-5.752a.479.479,0,0,0,0,.959H112.9v5.752a.479.479,0,0,0,.959,0v-5.752h5.752a.479.479,0,0,0,0-.959Z' transform='translate(-101.873 -101.873)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            }

            &:focus {
                box-shadow: none;
            }

            span {
                font-size: 17px;
                display: block;
                padding-right: 40px;
                font-weight: 600;

                @media (max-width: 767px) {
                    font-size: 15px;
                    line-height: 1.3;
                }
            }
        }
    }
}
