@import 'bootstrap';
@import 'variables';
@import 'courses';
@import 'quizzes';
@import 'blog';
@import 'contact';
@import 'faq';
@import 'live-lessons';
@import 'coaching';
@import 'slicks';
@import 'toastr';
@import 'rtl';
@import 'tenant-filter';
@import 'products';
@import 'bundles';


[dir="rtl"] {
    display: none;
}

.btn[disabled] {
    opacity: .4;
}

body .row {

    .cell {
        min-height: 40px;
    }
}

video {
    max-width: 100%;
}

#app {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    min-height: 100vh;
}

.coming-soon {
    height: 100vh;

    h1 {
        font-size: 25px;
        font-weight: normal;
        margin-top: 30px;
    }
}

.coming-soon-text {
    padding-top: 22vh;
    padding-left: 14vh;
    max-width: 50%;

    @media (max-width: 767px) {
        padding-top: 9vh;
        padding-left: 4vh;
        max-width: 95%;
    }
}

.coming-soon-bg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    object-fit: cover;
}

.overdue {
    height: 100vh;

    @media (max-width: 767px) {
        text-align: center;
    }

    h1 {
        font-size: 20px;
        font-weight: normal;
        margin-top: 22px;
        margin-bottom: 33px;
    }
}

.overdue-text {
    padding-top: 22vh;
    padding-left: 14vh;
    max-width: 660px;
    background-color: #ffffffc9;
    box-shadow: 0 0 45px #fff;
    border-radius: 5px;

    @media (max-width: 767px) {
        padding-top: 3vh;
        padding-left: 5px;
        padding-right: 5px;
        margin: 0 auto;
    }
}

.overdue-bg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    object-fit: cover;

    @media (max-width: 767px) {
        display: none;
    }
}

.paypal-section {
    min-height: calc(100vh - 312px);

    @media (max-width: 767px) {
        flex-direction: column-reverse;
        min-height: auto;
    }
}

.paypal-section-left {
    width: 32%;
    padding: 2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 767px) {
        width: 100%;
        padding: 40px 20px;
    }

    h1 {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 30px;
    }

    #paypal-button-container {
        position: sticky;
        top: 30px;
    }
}

.paypal-section-right {
    background-color: #F4F5F8;
    width: 68%;
    padding: 2vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 767px) {
        width: 100%;
        padding: 20px;
    }
}

.paypal-course-item {
    max-width: 523px;
    color: black;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 6px;
    margin-bottom: 30px;

    @media (max-width: 991px) {
        padding: 15px;
    }
    @media (max-width: 550px) {
        flex-direction: column;
    }

    &:hover {
        color: black;
        text-decoration: none;
    }

    > img {
        width: 130px;
        height: 73px;
        object-fit: cover;
        border-radius: 4px;

        @media (max-width: 550px) {
            margin: 0 auto 10px;
        }
    }
}

.paypal-course-item-price-col {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;

    span {
        color: #57616F;
        font-size: 14px;
        white-space: nowrap;
    }

    b {
        font-size: 14px;
        font-weight: 500;
        white-space: nowrap;
    }
}

.plus-minus-block {
    display: flex;
    align-items: center;

    label {
        margin: 0 10px;
    }

    .form-control {
        width: 63px;
        padding: 0 5px;
        height: auto;
        text-align: center;
        -moz-appearance: textfield;
        border-color: #d7e1ef;
        border-radius: 20px;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
    }

    .btn {
        border-radius: 50%;
        padding: 0;
        width: 26px;
        height: 26px;
        background: #F6F8FB;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        line-height: 1;

        &:hover {
            background: #e8ebf1;
        }
    }
}

.paypal-course-item-info {
    width: calc(100% - 130px);
    padding-left: 20px;

    @media (max-width: 550px) {
        width: 100%;
        padding-left: 0;
    }

    h2 {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.35;
        margin-bottom: 16px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* autoprefixer: off */
        -webkit-box-orient: vertical;
        /* autoprefixer: on */
    }

    table {
        font-size: 14px;
        width: 100%;
        margin-bottom: 10px;

        td {

            &:first-child {
                white-space: nowrap;
            }

            &:last-child {
                text-align: right;
                padding-left: 15px;
                font-size: 16px;
            }
        }
    }

    .live-session-item-left {
        position: static;
        padding-left: 0;
        border: none;
    }
}

.paypal-course-item-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #D7E1EF;
    padding-top: 12px;
    width: 100%;

    span {
        color: #1B1C1D;
        font-size: 12px;
    }

    b {
        font-size: 14px;
        font-weight: 500;
    }

    del {
        color: #57616F;
        font-size: 12px;
    }
}

.gift-form-group {
    height: 107px;

    input {
        height: 51px;
        border-radius: 33px;
    }
}

.pay-thankyou {
    background-color: #F4F5F8;
    padding: 5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
        font-size: 22px;
        text-align: center;
        margin: 18px 0 42px;
        font-weight: 700;
        color: #0E0E0E;
        line-height: 1.35;
    }
}

.pay-thankyou-courses {
    padding: 60px 10px;

    .courses-container, .products-container, .bundles-container{
        min-height: auto;
    }
    .courses-container > h2 {
        font-size: 24px;
    }
}

.toast-success {
    background-color: var(--btn1-background-color);
    color: var(--btn1-text-color);
}

.page-404 {
    height: 100vh;
    padding: 30px 0;
}

.text-404 {
    padding-left: 5vw;
    width: 49%;

    @media (min-width: 1500px) {
        padding-left: 10vw;
    }
    @media (max-width: 767px) {
        width: 100%;
        padding: 0 20px;
    }

    h1 {
        font-size: 25px;
        font-weight: 400;
        margin-bottom: 20px;

        @media (min-width: 1500px) {
            font-size: 40px;
        }
    }

    p {
        font-size: 18px;
        margin-bottom: 40px;
    }
}

.img-404 {
    width: 51%;

    @media (max-width: 767px) {
        width: 100%;
    }
}


.home-course-section {
    max-width: 1140px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    padding: 12px 24px;

    @media (max-width: 1159px) {
        max-width: 768px;
    }
    @media (max-width: 767px) {
        max-width: 396px;
    }
}

.home-course-section-container {
    flex-wrap: wrap;
    margin-left: -12px;
    margin-right: -12px;
    justify-content: center;

    @media (max-width: 767px) {
        margin: 0 auto;
        max-width: 332px;
    }

    .btn-1-outline {
        margin-top: 30px;
    }
}

.home-course-item-block {
    height: auto;
    padding: 12px;
    transition: color .3s;
    width: 33.333333%;

    &:hover {
        color: #1D1D1D;
        text-decoration: none;

        .home-course-author, .home-course-info-block, .home-course-price {
            background-color: var(--course-background-hover);
            color: var(--course-color-hover);
        }

        .home-course-item {
            box-shadow: 0 2px 6px #0000000f;
        }

        hr {
            border-color: var(--course-color-hr-hover);
        }
    }

    hr {
        margin: 0;
        transition: all .3s;
    }

    @media (max-width: 1159px) {
        width: 50%;
    }
    @media (max-width: 767px) {
        width: 100%;
        max-width: 348px;
        padding: 12px 0 12px;
    }
}

.home-course-item {
    width: 100%;
    color: #1D1D1D;
    border: 1px solid #eaebf4;
    display: block;
    border-radius: var(--course-radius);
    position: relative;
    transition: all .3s;
}

.home-course-item-img {
    height: 186px;
    width: 100%;
    object-fit: cover;
    border-radius: var(--course-radius) var(--course-radius) 0 0;
}

.home-course-author {
    background-color: var(--course-background);
    color: var(--course-color);
    padding: 10px 20px;
    display: flex;
    align-items: center;
    border-top: solid 1px var(--course-border);
    border-left: solid 1px var(--course-border);
    border-right: solid 1px var(--course-border);
    transition: background-color .3s;

    img {
        width: 38px;
        height: 38px;
        object-fit: cover;
        margin-right: 8px;
        border-radius: 50%;
    }

    span {
        font-size: 14px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-grow: 1;
    }
}

.home-course-info-block {
    background-color: var(--course-background);
    color: var(--course-color);
    padding: 10px 20px;
    height: 170px;
    border-left: solid 1px var(--course-border);
    border-right: solid 1px var(--course-border);
    transition: background-color .3s;

    h2 {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
        margin-bottom: 11px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    p {
        font-size: 14px;
        line-height: 1.4;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 0;
    }
}

.home-course-price {
    background-color: var(--course-background);
    color: var(--course-color);
    transition: background-color .3s;
    padding: 10px 20px;
    line-height: 1.2;
    height: 48px;
    border-radius: 0 0 var(--course-radius) var(--course-radius);
    border-left: solid 1px var(--course-border);
    border-right: solid 1px var(--course-border);
    border-bottom: solid 1px var(--course-border);

    p {
        font-size: 14px;
        padding-right: 10px;

        @media (max-width: 358px) {
            font-size: 12px;
        }
    }

    span {
        font-size: 16px;
        font-weight: 700;

        del {
            font-size: 15px;
            opacity: 0.7;
            margin-right: 10px;
            display: inline-block;

            @media (max-width: 400px) {
                font-size: 14px;
            }
            @media (max-width: 358px) {
                margin-right: 7px;
                margin-bottom: 0;
            }
        }
    }
}

.home-course-section.home-course-section-slider {

    .home-course-item {
        text-align: left;
    }
}

.home-course-section-container.slick-slider {

    @media (max-width: 767px) {
        max-width: 410px;
        margin: 0 -6px;
    }

    .slick-prev, .slick-next {
        width: 24px;
        height: 30px;
        opacity: 1;
        border-radius: 50%;
        color: inherit;
        z-index: 1;

        &:before {
            border: solid black;
            border-width: 0 2px 2px 0;
            content: "";
            display: inline-block;
            padding: 6px;
            border-radius: 0;
            background-color: transparent;
            color: inherit;
        }
    }

    .slick-next {
        right: -19px;

        @media (min-width: 1280px) {
            right: -20px;
        }
        @media (max-width: 830px) {
            right: -11px;
        }
        @media (max-width: 767px) {
            right: -16px;
        }

        &:before {
            margin: 0 4px 0 0;
            transform: rotate(-45deg);
            border-color: inherit;
            border-width: 0 2px 2px 0;
            padding: 6px;
        }
    }

    .slick-prev {
        left: -19px;

        @media (min-width: 1280px) {
            left: -20px;
        }
        @media (max-width: 830px) {
            left: -11px;
        }
        @media (max-width: 767px) {
            left: -16px;
        }

        &:before {
            margin: 0 0 0 4px;
            transform: rotate(135deg);
            border-color: inherit;
            border-width: 0 2px 2px 0;
            padding: 6px;
        }
    }

    .slick-slide {
        height: auto;
    }

    .home-course-item-block {

        @media (max-width: 767px) {
            padding: 12px 6px 12px;
            max-width: inherit;
        }
    }
}

.global-form {
    padding: 24px 24px;
    max-width: 1140px;
    margin: 0 auto;

    @media (max-width: 1159px) {
        max-width: 768px;
    }

    form {
        margin-bottom: 0;
    }
}

.background-landing {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 500px;
    flex-direction: column;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    h2 {
        padding: 0 24px;
        text-align: center;
        margin-bottom: 30px;

        @media (max-width: 767px) {
            font-size: 26px;
        }
    }
}

.edit-page-builder {
    position: fixed;
    top: 200px;
    right: 50px;
    z-index: 99999;
}

.cookie-section {
    position: sticky;
    left: 0;
    right: 0;
    bottom: 10px;
    width: 98%;
    max-width: 1186px;
    margin: 0 auto;
    padding: 15px 25px;
    display: flex;
    align-items: center;
    border-radius: 14px;
    background: #fff;
    z-index: 10;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

@media (max-width: 991px) {
    .cookie-section {
        padding: 15px 20px;
    }
}

@media (max-width: 767px) {
    .cookie-section {
        flex-wrap: wrap;
        padding-top: 40px;
    }
}

.cookie-section > img {
    margin-right: 20px;
}

@media (max-width: 767px) {
    .cookie-section > img {
        display: none;
    }
}

@media (max-width: 991px) {
    .cookie-section p {
        font-size: 14px;
        line-height: 1.4;
    }
}

@media (max-width: 767px) {
    .cookie-section p {
        margin-bottom: 10px;
    }
}

.cookie-section .btn {
    border-radius: 6px;
    border: 1px solid #c4c4d2;
    margin-left: 12px;
    white-space: nowrap;
    font-size: 14px;
    padding: 9px 17px;
    color: #000000;
    margin-bottom: 8px;
}

.cookie-section .btn:hover {
    background-color: #f1f1f1;
}

.cookie-section .btn-close {
    padding: 3px 10px;
    border: none;
    background-color: #ffffff;
    margin-left: 25px;
    transition: transform 0.3s;
    position: absolute;
    top: -30px;
    right: 15px;
    border-radius: 4px 4px 0 0;
}

.cookie-section .btn-close:hover {
    transform: scale(1.2);
}

@media (max-width: 991px) {
    .edit-page-builder {
        display: none;
    }
}


.productCheckoutTitle {
    font-size: 25px;
    margin-top: 38px;
    font-weight: 600;
}

.checkout-container {
    max-width: 1000px;
    padding-top: 100px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
    width: 100%;

    @media (max-width: 1199px) {
        padding-top: 40px;
        max-width: 1110px;
    }

    .oneItem {
        max-width: 332px;
        height: auto;

        .detailsBox {
            height: auto;
        }
    }
}

.borderBox {
    border-right: 2px solid #fff;
}

.btn-download {
    border: none;
    position: absolute;
    right: 7px;
    bottom: 21px;
    background-color: transparent;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    color: #414141;
    transition: all 0.2s;
    padding: 13px;
    outline: none;
    border-radius: 0 0 15px 0;
}

.full-screen-overlay {
    overflow: hidden;
}

.full-screen-editor {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
}

.full-screen-editor .ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline,
.full-screen-editor .ck.ck-editor__main,
.full-screen-editor .ck.ck-editor__main .ck-source-editing-area {
    height: 100%;
    max-height: none;
}


.home-static-container {
    max-width: 1092px;
    width: 100%;
    margin: 0 auto;
    z-index: 1;
    position: relative;
    padding: 12px 24px;

    @media (max-width: 1159px) {
        max-width: 768px;
    }
}

.home-static-container-2 {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    z-index: 1;
    padding: 12px 24px;
    position: relative;
}

.home-static-container-3 {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    z-index: 1;
    padding: 12px 24px;
    position: relative;
}

.landing-container {
    max-width: 1140px;
    padding: 24px 24px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 767px) {
        flex-direction: column-reverse;
        max-width: 420px;
    }
}

.landing-container-reverse {

    @media (max-width: 767px) {
        flex-direction: column;
    }

    .landing-right {
        padding: 12px 24px 12px 0;

        @media (max-width: 767px) {
            padding: 0;
        }
    }
}

.theme-all-form {
    background-color: black;
    color: #ffffff;
    padding: 60px 0;

    @media (max-width: 767px) {
        padding: 36px 0;
    }
}

.theme-all-form-bg {
    position: absolute;
    z-index: 0;
    object-fit: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
}

.theme-all-form-container {
    max-width: 768px;
    margin: 0 auto;
    padding: 12px 24px;
    position: relative;
    z-index: 1;

    > p {
        color: inherit;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 36px;

        @media (max-width: 767px) {
            font-size: 16px;
            margin-bottom: 24px;
            line-height: 22px;
        }
    }

    form {
        max-width: 450px;
        margin: 0 auto;

        .form-control {
            background-color: transparent;
            border: none;
            border-radius: 0;
            padding-left: 6px;
            color: inherit;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: inherit;

            &.is-invalid {
                border-color: #dc3545;
            }

            &::placeholder {
                opacity: 1;
                color: inherit;
            }

            &:focus {
                box-shadow: none;
            }
        }

        textarea.form-control {
            resize: none;
            height: 80px;
        }
    }
}


.landing-static {
    padding: 30px 0;

    @media (max-width: 991px) {
        padding: 10px 0;
    }
    @media (max-width: 767px) {
        margin: 0 auto;
        max-width: 420px;
    }

    &:last-child {
        margin-bottom: 40px;
    }
}

.landing-left {
    width: 46%;
    max-width: 500px;

    @media (max-width: 767px) {
        width: 100%;
        max-width: 556px;
    }
}

.landing-img {
    position: relative;
    z-index: 1;
    width: 100%;
    height: auto;
}

.landing-right {
    width: 54%;
    max-width: 525px;
    padding: 12px 0 12px 24px;

    @media (max-width: 767px) {
        width: 100%;
        max-width: inherit;
        padding: 0;
        margin-bottom: 20px;
    }

    > h2 {
        font-size: 30px;
        font-weight: 400;
        margin-bottom: .9em;
        min-height: 25px;

        @media (max-width: 991px) {
            font-size: 22px;
        }
        @media (max-width: 767px) {
            font-size: 20px;
        }

        &:lang(hy) {
            font-weight: 600;

            @media (min-width: 1160px) {
                font-size: 28px;
            }
        }
    }

    > p {
        font-size: 17px;
        line-height: 1.5;
        margin-bottom: 2em;
        min-height: 22px;

        @media (max-width: 991px) {
            font-size: 14px;
            line-height: 1.4;
            margin-bottom: 1.5em;
        }

        &:lang(hy) {

            @media (min-width: 1160px) {
                font-size: 16px;
                line-height: 1.4;
            }
        }
    }

    .btn {

        @media (max-width: 991px) {
            font-size: .9em;
        }
    }
}

.landing-container-full {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 1vw;

    @media (max-width: 1159px) {
        font-size: 1.3vw;
    }
    @media (max-width: 767px) {
        font-size: 10px;
    }
}

.landing-container-full-reverse {

    @media (max-width: 767px) {
        flex-direction: column-reverse;
    }
}

.landing-container-full-text {
    width: 40%;
    padding: 20px 24px;
    margin: 0 auto;

    @media (max-width: 1159px) {
        width: 50%;
    }
    @media (max-width: 767px) {
        width: 100%;
        padding: 24px;
    }

    > h2 {
        font-size: 2em;
        margin-bottom: 0.8em;
        min-height: 25px;
    }

    > p {
        margin-bottom: 1.7em;
        font-size: 1.18em;
        line-height: 1.5;
        min-height: 22px;

        @media (max-width: 1159px) {
            margin-bottom: 1.2em;
        }
        @media (max-width: 767px) {
            font-size: 15px;
            line-height: 1.4;
        }
    }

    .btn {
        font-size: 1em;

        @media (max-width: 767px) {
            font-size: 14px;
        }
    }
}

.landing-container-full-img {
    width: 50%;

    @media (max-width: 767px) {
        width: 100%;
    }

    > img {
        width: 100%;
        object-fit: cover;
        height: auto;

        @media (max-width: 767px) {
            height: auto;
        }
    }
}

.theme-all-info-section-1-container {
    max-width: 730px;
    margin: 0 auto;

    h1 {
        font-weight: 700;
        font-size: 36px;
        margin-bottom: 36px;

        @media (max-width: 767px) {
            font-size: 30px;
            margin-bottom: 24px;
        }

        &:lang(hy) {
            font-size: 32px;

            @media (max-width: 767px) {
                font-size: 26px;
            }
        }
    }

    > p {
        font-size: 18px;

        @media (max-width: 767px) {
            font-size: 16px;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.theme-all-info-section-2-container {
    max-width: 770px;
    margin: 0 auto;
    padding: 68px 20px 72px;

    @media (max-width: 767px) {
        padding: 40px 20px 40px;
    }

    > h2, > h1 {
        font-weight: 700;
        font-size: 36px;
        margin-bottom: 36px;
        text-align: center;
        min-height: 25px;

        @media (max-width: 767px) {
            font-size: 24px;
            margin-bottom: 24px;
        }

        &:lang(hy) {
            font-size: 32px;

            @media (max-width: 767px) {
                font-size: 22px;
            }
        }
    }

    > p {
        font-size: 18px;
        min-height: 22px;

        @media (max-width: 767px) {
            font-size: 16px;
        }
    }

    iframe {
        margin-bottom: 20px;
        margin-top: 20px;
        width: 100%;
        height: calc(55.5vw - 24px) !important;
        max-height: 420px;
    }
}


.pm-10 {
    padding: 12px 0;
    width: 100%;
}

.p-10 {
    padding: 10px 0;
}

[id^="i"]:not([data-gjs-type]):not([class]) {
    max-width: 100%;

    .gjs-lory-frame {
        max-width: 100%;
    }
}

.gjs-lory-slides {
    max-width: 100%;
}

.gjs-lory-frame {
    padding: 10px 0;
    margin: 10px auto !important;
    max-width: 100%;
}

.gjs-lory-slide {
    max-width: 100%;
    margin-right: 0 !important;
}

[data-accordions], [data-tabs] {
    padding: 24px 24px;
    width: 100%;

    .accordion {
        margin: 0 0 6px 0;
        color: inherit;
        background-color: inherit;
        font-weight: 600;
        position: relative;
        font-size: 18px;
        padding: 7px 34px 7px 0;

        @media (max-width: 767px) {
            font-size: 17px;
        }

        &:hover {
            text-decoration: none;
        }

        svg {
            position: absolute;
            right: 0;
            top: 10px;
            opacity: .7;
        }
    }

    .accordion-content {
        margin-bottom: 5px;
        border: none;
        opacity: .8;
        padding: 7px 0;
        font-size: 18px;
        min-height: 60px;

        @media (max-width: 767px) {
            font-size: 16px;
        }
    }

    .tab {

        &:hover {
            text-decoration: none;
        }
    }

    .accordion-active {

        .accordion {

            svg {
                path:nth-child(2) {
                    display: none;
                }
            }
        }
    }
}


.basic-section-3-container, .basic-section-4-container-1, .basic-section-5-container-1, .home-static-container, .home-static-container-2,
.home-static-container-3, .theme-4-service {

    .home-course-section, .live-section, .products-container-wrapper, .blog-page {
        padding: 0;
    }

    .landing-container {
        padding: 24px 0;
    }

    .landing-container-full {
        font-size: 14px;
        max-width: 1092px;
        margin: 0 auto;
    }

    .landing-container-full-text {
        width: 50%;
        padding-left: 0;
    }

    .landing-container-full-reverse {

        .landing-container-full-text {
            padding-right: 0;
            padding-left: 24px;
        }
    }

    .global-form {
        padding: 12px 0;
        margin: 0 auto;
    }

    [data-accordions], [data-tabs] {
        padding: 24px 0;
    }

    > .row {
        padding: 10px 0;
        margin-left: -10px;
        margin-right: -10px;
    }

    iframe {
        width: 100%;
    }

    .theme-all-form-container {
        padding: 24px 0;
    }

    @media (min-width: 768px) and (max-width: 1159px) {

        .landing-right {

            > h2 {
                font-size: 24px;
                margin-bottom: 0.7em;
            }

            > p {
                font-size: 14px;
                margin-bottom: 1.5em;
            }

            .btn {
                font-size: 0.9em;
            }
        }
        .landing-container-full {
            max-width: 720px;
            font-size: 12px;
        }
    }
    @media (max-width: 767px) {

        .landing-container {
            padding: 12px 0;
        }
        .landing-container-full {
            max-width: 400px;
            font-size: 10px;
        }
        .landing-container-full-text, .landing-container-full-reverse .landing-container-full-text {
            width: 100%;
            padding: 24px 0;
        }
        .home-course-section, .blog-page {
            max-width: 348px;
        }
        .live-section .first-live-container.slick-slider {
            padding: 0;
            margin: 0 -6px;
        }
        .live-section.live-section-slider {
            max-width: 520px;
        }
        .live-section .first-live-container.slick-slider .slick-next {
            right: -15px;
        }
        .live-section .first-live-container.slick-slider .slick-prev {
            left: -15px;
        }
    }
}

.home-static-container-2, .home-static-container-3 {

    .home-course-section, .live-section, .products-container-wrapper, .blog-page, .landing-container {
        max-width: 1092px;

        @media (max-width: 1159px) {
            max-width: 720px;
        }
        @media (max-width: 767px) {
            max-width: 400px;
        }
    }
}

.products-container-wrapper, .global-form, .blog-page, .live-section, .home-course-section,
.theme-8-special-content, .theme-8-people-left, .basic-section-2-right, .theme-7-header-text, .home-studies-left {

    .products-container-wrapper, .global-form, .blog-page, .live-section, .home-course-section {
        padding: 0;
        margin: 0 auto;
    }

    [data-accordions], [data-tabs] {
        padding: 24px 0;
        margin: 0 auto;
    }

    .row {
        padding: 10px 0;
        margin-left: -10px;
        margin-right: -10px;

        .row {
            padding: 0;
            margin: 0;
        }
    }

    .landing-container {
        padding: 24px 0;
    }

    .landing-container-full {
        font-size: 14px;
        max-width: 1092px;
        margin: 0 auto;
    }

    .landing-container-full-text {
        width: 50%;
        padding-left: 0;
    }

    .landing-container-full-reverse {

        .landing-container-full-text {
            padding-right: 0;
            padding-left: 24px;
        }
    }

    .theme-all-form-container {
        padding: 24px 0;
    }

    @media (max-width: 767px) {
        .landing-container {
            padding: 12px 0;
        }
        .landing-container-full {
            max-width: 400px;
            font-size: 10px;
        }
        .landing-container-full-text, .landing-container-full-reverse .landing-container-full-text {
            width: 100%;
            padding: 24px 0;
        }
    }
}

.membership-section {
    padding: 60px 12px;

    @media (max-width: 470px) {
        padding: 48px 3px;
    }
}

.membership-container {
    max-width: 1180px;
    margin: 0 auto;

    @media (max-width: 1199px) {
        max-width: 798px;
    }
    @media (max-width: 767px) {
        max-width: 375px;
    }
    h1 {
        font-weight: 600;
        font-size: 35px;
        margin-bottom: 24px;

        @media (max-width: 470px) {
            font-size: 30px;
            margin-bottom: 20px;
        }
    }
}

.membership-item-tenant {
    width: 33.33%;
    padding: 12px;

    @media (max-width: 1199px) {
        width: 50%;
    }
    @media (max-width: 767px) {
        width: 100%;
    }
}

.membership-item-tenant-head {
    background: #131212;
    color: #ffffff;
    padding: 12px 20px;
    border-radius: 10px 10px 0 0;
    border: solid 1px transparent;
    min-height: 110px;

    h2 {
        font-weight: 400;
        font-size: 18px;
        min-height: 43px;
    }
    p {
        font-weight: 400;
        font-size: 16px;
        display: flex;
        align-items: center;
        white-space: nowrap;
        padding-left: 15px;

        b {
            font-weight: 600;
            font-size: 16px;
        }

        del {
            font-size: 12px;
            color: #A7A7A7;
            margin-right: 5px;
        }
    }
}

.membership-item-tenant-content {
    border: 1px solid #D5D5E0;
    border-top: none;
    border-bottom: none;
    padding: 12px 14px 10px;
    background-color: #ffffff;

    p {
        color: #7C7C7C;
        margin-bottom: 24px;
        height: 72px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    h3 {
        color: #5E5E5E;
        font-weight: 400;
        font-size: 12px;
        margin-bottom: 10px;
    }
}

.membership-item-tenant-list {
    display: flex;
    margin-bottom: 0;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0;

    li {
        width: 49%;
        color: #2d2c31;
        display: flex;
        align-items: center;
        padding: 5px 0;
        position: relative;
        font-size: 12px;

        svg {
            margin-right: 3px;
            flex: 0 0 20px;
        }
    }
}
.membership-item-description{
    background-color: #E8EBF0;
    padding: 10px 24px;

    h3{
        color: #5E5E5E;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 5px;
    }
    p{
        font-weight: 300;
        font-size: 11px;
        line-height: 1.4;
        margin-bottom: 0;
    }
}
.membership-item-tenant-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 16px 0 0;
    border: 1px solid #D5D5E0;
    border-top: none;
    background-color: #ffffff;
    border-radius: 0 0 6px 6px;
}

.membership-item-tenant-footer-info {
    display: flex;
    flex-direction: column;
    border: 1px solid #D5D5E0;
    border-top: none;
    background-color: #ffffff;

    & .membership-info-header {
        padding: 10px 24px;
        background-color: #F4F5F8;
    }

    & .membership-info-content {
        display: flex;
        flex-direction: column;
        padding: 20px 20px 20px;
        align-items: flex-start;

        & a {
            display: flex;
            gap: 5px;
            align-items: center;
            padding: 6px 0;
            text-decoration: none;
            color: black;
            max-width: 100%;
            margin: 4px 0;

            & svg {
                flex-shrink: 0;
                margin-right: 4px;
            }

            &:hover {
                color: #007bff;
            }

            & span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.membership-item-tenant-info {
    border-left: 1px solid #D5D5E0;
    border-right: 1px solid #D5D5E0;
    border-bottom: 1px solid #D5D5E0;
}

.membership-item-tenant-info-title {
    background: #F8F8FA;
    font-weight: 600;
    font-size: 16px;
    padding: 12px 24px;

    span {
        color: #808081;
        font-weight: 400;
        margin-right: 10px;
    }
}

.membership-item-tenant-info-list {
    padding: 12px 24px !important;

    @media (max-width: 470px) {
        padding: 12px 15px;
    }

    li {
        padding: 10px 0;

        a {
            display: flex;
            align-items: flex-start;
            color: black;
            line-height: 24px;
            padding: 5px 0;

            &:hover {
                color: var(--color-1);
                text-decoration: none;
            }

            svg {
                margin-right: 10px;
                flex: 0 0 20px;
                width: 20px;
                margin-top: 2px;
            }
        }
    }
}

.social-share-btn {
    cursor: pointer;

    @media (min-width: 768px) {
        display: none;
    }
}


.pay-product-page {
    background: #F4F5F8;
    padding: 40px 20px;

    @media (max-width: 575px) {
        padding: 0;
    }
}

.pay-product-container {
    max-width: 850px;
    margin: 0 auto;
    padding: 15px 20px 40px;
    background-color: #ffffff;

    @media (max-width: 575px) {
        padding: 10px 15px 20px;
    }

    hr {
        margin: 27px 0;
    }
}

.pay-product-item {
    border-radius: 4px;
    background: #F3F5F7;
    max-width: 758px;
    margin: 0 auto;
    padding: 14px;
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
        flex-direction: column;
        max-width: 480px;
    }
}

.pay-product-item-img {
    width: 260px;

    img {
        width: 100%;
        height: 147px;
        border-radius: 4px;
        object-fit: cover;
    }
}

.pay-product-item-info {
    padding-left: 25px;
    width: calc(100% - 260px);

    @media (max-width: 767px) {
        width: 100%;
        padding-left: 0;
        margin-top: 10px;
    }

    h2 {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
    }

    table {
        width: 100%;
        font-size: 14px;
        border-bottom: solid 1px #D7E1EF;

        th, td {
            padding: 6px 0;
        }

        th {
            color: #57616F;
            font-weight: 400;
        }

        td {
            font-weight: 500;
            padding-left: 15px;
            text-align: right;

            .plus-minus-block {
                display: inline-flex;

                label {
                    margin: 0 4px;
                }

                .form-control {
                    width: 30px;
                    border: none;
                    background-color: transparent;
                    color: #000000;
                    font-weight: 500;
                    font-size: 14px;
                    border-radius: 4px;
                }
            }
        }
    }
}

.onePaymentMethod {
    max-width: 384px;
    width: 150px;
    height: 60px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin: 10px;
    border: 1px solid #D9DCE4;
    color: #000;
    transition: all .3s;

    &:not(.selected) {
        &:hover {
            border-color: #3978C2;
        }
    }

    p {
        margin-bottom: 0;
    }

    img {
        width: 100px;
        height: 33px;
        object-fit: contain;
    }

    &.selected {
        text-decoration: none;
        transform: scale(1.08);
        box-shadow: 0 0 10px #3978C2;
    }
}

.pay-product-item-total {
    margin-top: 8px;

    p {
        font-weight: 700;
        font-size: 12px;
    }

    strong {
        font-weight: 700;
        font-size: 14px;
    }
}

.pay-product-sign-up {
    max-width: 700px;
    margin: 0 auto;

    h2 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 25px;
    }

    table {

        td, th {
            padding: 4px 15px 4px 0;
            font-size: 14px;
        }

        th {
            color: #57616F;
            font-weight: 400;
        }

        td {
            font-weight: 500;
        }
    }
}

.pay-form-group {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    @media (max-width: 575px) {
        grid-template-columns: 1fr;
    }

    label {
        font-size: 14px;
    }

    .form-control {
        border-radius: 4px;
        border: 1px solid #A7B3C0;
        height: calc(1.5em + 0.75rem + 4px);
        color: #000000;

        &::placeholder {
            color: #CECECE;
            font-size: 14px;
        }
    }
}

.pay-product-method {
    max-width: 700px;
    margin: 0 auto;

    h2 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 25px;
    }

    label {
        font-size: 14px;
    }
}
.pay-product-method-block{
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 15px;

    @media (max-width: 575px) {
        grid-template-columns: 1fr 1fr;
    }
    .onePaymentMethod{
        margin: 0;
        width: 100%;
    }
}

.pay-product-card {
    border: 1px solid #ced4da;
    padding: 9.5px 10px;
    border-radius: 0.25rem;
}

.form-group-btn {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    overflow: hidden;
    padding: 1px 5px;

    .form-control {
        border: none;
        border-radius: 0;
        height: auto;
        color: #000000;

        &:focus {
            box-shadow: none;
            outline: none;
        }
    }

    .btn {
        white-space: nowrap;
        border-radius: 4px;
        background: #3978C2;
        padding: 4px 22px;
        color: #ffffff;
        font-size: 14px;
        letter-spacing: 0;

        &:hover {
            background: #2b6ab4;
        }
    }
}

.btn-payment-method {
    border-radius: 4px !important;
    border: 1px solid #3978C2;
    padding: 10px;

    img {
        width: 36px;
        height: 24px;
        object-fit: contain;
    }
}

.btn-open-card {
    border-radius: 4px !important;
    border: 1px solid #E5E5E5;
    padding: 10px;

    &:hover {
        border-color: #c9c9c9;
    }
}

.pay-product-finish {

    .btn-dark {
        border-radius: 4px;
        background: #272323;
        min-width: 250px;

        &:hover{
            color: #ffffff;
            background: #1f1d1d;
        }
        @media (max-width: 767px) {
            min-width: auto;
            padding: 7px 16px;
            font-size: 14px;
        }
    }
}

.promo-code-wrapper {

    .btn.btn-1{
        font-size: 12px!important;
        padding: 2px 8px!important;
        white-space: nowrap!important;
        border-radius: 0 4px 4px 0!important;
        background-color: #7A8291!important;
        color: #ffffff!important;
    }
    .underline_text {
        text-decoration: underline;
        background: none;
        border: none;
        display: block;
        text-align: right;
        margin-left: auto;
    }
    .form-control{
        background-color: #ffffff !important;
        color: #000000 !important;
        border-color: #E8EBF0!important;
        font-size: 12px;
        height: 25px;
        border-radius: 4px 0 0 4px;
        padding: 3px 8px;
    }
}
.btn.btn-subscribe{
    font-size: 13px !important;
    white-space: nowrap !important;
    background-color: #0C0C0C !important;
    border-radius: 4px !important;
    padding: 0.33em 1.1em;
    color: #ffffff!important;

    &.btn-light{
        background-color: #7A8291!important;
        color: #ffffff!important;
    }
}
.promo-code-block{
    padding: 0 16px;
    margin-bottom: 15px;
}
.btn.fz-12{
    font-size: 12px!important;
}
.promo-code-included{

    p{
        font-size: 12px;
        margin-bottom: 5px;
    }
    .btn.btn-1{
        font-size: 13px;
        border-radius: 4px !important;
        padding: 0.33em 1.1em;
    }
}
.subscribe-sub{
    border-top: solid 1px #E8EBF0;
    padding: 10px 15px;
}
.free-trial-p{
    color: #6F6F6F;
    font-size: 11px;
    min-height: auto;
}

.pay-product-sign-up {

    .form-group-container {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr;
        align-items: flex-start;
        max-width: 100%;
        gap: 20px;

        @media (max-width: 767px) {
            grid-template-columns: 1fr;
        }
        .form-group {
            margin: 0;
        }
        .col-sm-12{
            padding: 0;

            .label{
                margin-bottom: 0.5rem;
                display: inline-block;
            }
        }
        .custom-checkbox-glogal, .custom-radio-glogal{
            margin-bottom: .2em;
        }
    }
}








