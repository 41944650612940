
.bundles-container {
    max-width: 1076px;
    margin: 0 auto;
    padding: 5vh 20px 100px;
    position: relative;
    z-index: 1;
    min-height: calc(100vh - 60px);

    @media (min-width: 1500px) {
        max-width: 1428px;
    }
    @media (max-width: 1199px) {
        max-width: 724px;
    }
    @media (max-width: 767px){
        max-width: 372px;
    }
    > h2{
        font-size: 24px;
    }
}
.bundles-content{
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .no-results{
        grid-column: span 3;
    }
}
.bundles-item{
    position: relative;
    border-radius: 11px;
    overflow: hidden;
    transition: background-color .3s, color .3s, border .3s;
    border: 1px solid #eaebf4;
    color: #000000;
    background-color: #ffffff;
    flex: 0 0 calc(33.333% - 13.34px);

    @media (min-width: 1500px) {
        flex: 0 0 calc(25% - 15px);
    }
    @media (max-width: 1199px){
        flex: 0 0 calc(50% - 10px);
    }
    @media (max-width: 767px){
        flex: 0 0 100%;
    }
}
.bundles-item-img{
    width: 100%;
    height: 186px;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.bundles-item-info{
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 186px);

    h2{
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 12px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 1.389;
        min-height: 50px;
    }
}
.bundles-item-description{
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 12px;
    line-height: 1.5715;
}
.bundles-item-price-more{
    display: flex;
    flex-wrap: wrap;
    margin-top: auto;
    align-items: center;

    .btn-sm{
        padding: 0.393em 1em;
    }
}
.bundles-item-price{
    padding-right: 15px;
    line-height: 1.5;
    margin: 10px 0;
    white-space: nowrap;

    del{
        opacity: .6;
        margin-right: 4px;
        font-size: 14px;
    }
    span{
        font-weight: 600;
        font-size: 16px;
    }
}




.bundle-section {
    background-color: #F4F5F8;
    padding: 40px 0;
    min-height: 360px;
}
.bundle-container {
    max-width: 1100px;
    margin: 0 auto;
    padding-right: 480px;
    padding-left: 20px;

    @media (max-width: 1160px) {
        padding-right: 410px;
    }
    @media (max-width: 991px) {
        padding-right: 20px;
        max-width: 650px;
    }
    @media (max-width: 575px) {
        padding-right: 15px;
        padding-left: 15px;
        max-width: 362px;
    }
}
.bundle-head {

    h1 {
        font-size: 30px;
        font-weight: 600;
        line-height: 1.5;
        margin-bottom: 19px;

        @media (max-width: 767px) {
            font-size: 22px;
            line-height: 1.4;
        }
    }

    h2 {
        font-size: 18px;
        margin-bottom: 13px;
    }

    p {
        line-height: 1.55;
    }
}
.bundle-pay {
    position: fixed;
    right: calc(50vw - 550px);
    top: 80px;
    border-radius: 6px;
    width: 260px;
    background-color: #ffffff;
    border: solid 1px #D6D7D9;
    overflow: hidden;
    z-index: 3;

    @media (max-width: 1160px) {
        right: 2vw;
    }
    @media (max-width: 991px) {
        position: static;
        margin: 30px auto 0;
    }

    > img {
        width: 100%;
        height: 145px;
        object-fit: cover;
    }
}
.bundle-pay-info {
    padding: 15px 20px;

    .btn{
        font-size: 14px;
        padding: 0.6em 1.5em;
        letter-spacing: 0;
    }
    h3 {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 12px;
    }

    h4 {
        font-size: 13px;
        font-weight: 400;
        margin-bottom: 12px;

        > img {
            margin-right: 13px;
        }
    }

    p {
        font-size: 14px;
        margin-bottom: 11px;
    }

    h5 {
        font-size: 14px;
        font-weight: 400;
        color: #A3A3A3;
    }

    h6 {
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 16px;
    }
}
.bundle-section-2 {
    background-color: #E3E5EB;
    padding: 18px 0;

    h2 {
        font-size: 20px;
        position: relative;

        &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            margin-left: -0.5em;
            bottom: -27px;
            left: 20px;
            display: inline-block;
            border-style: solid;
            border-width: 10px 25px 0 25px;
            border-color: #E3E5EB transparent transparent transparent;
        }
    }
}
.bundle-course-item {
    max-width: 610px;
    border: solid 1px #C8CBD4;
    border-radius: 7px;
    height: 96px;
    overflow: hidden;
    margin-bottom: 10px;
    color: black;

    @media (max-width: 575px) {
        border-radius: 6px;
        flex-direction: column;
        height: auto;
    }

    &:hover {
        text-decoration: none;
        color: #363636;
    }

    > img {
        width: 167px;
        flex: 0 0 167px;
        height: 100%;
        object-fit: cover;

        @media (max-width: 575px) {
            width: 100%;
            flex: 0 0 auto;
            height: 186px;
        }
    }
}
.bundle-course-item-info {
    padding: 10px 16px 10px 20px;
    height: 100%;

    @media (max-width: 575px) {
        height: auto;
        padding: 14px 16px 14px 16px;
    }

    h3 {
        font-size: 16px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 1.4;

        @media (max-width: 575px) {
            -webkit-line-clamp: 3;
            margin-bottom: 12px;
        }
        b{
            font-weight: 600;
        }
    }

    p {
        font-size: 14px;
    }
}
.bundle-h2 {
    font-size: 20px;
}


.bundle-section-price {
    background-color: #313131;
    color: #ffffff;
    position: sticky;
    bottom: 0;
    z-index: 3;

    .bundle-title {
        height: 26px;
        max-width: 235px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
}
.bundle-section-price-container {
    max-width: 1100px;
    padding: 20px;
    margin: 0 auto;

    @media (max-width: 991px) {
        padding: 10px 15px;
    }
}
.bundle-section-price-item {
    padding-right: 40px;
    padding-left: 40px;

    @media (max-width: 991px) {
        padding-left: 0;
        padding-right: 30px;
        border: none;
        margin: 10px 0;
    }

    &:first-child {
        padding-left: 0;
    }

    .border-right {
        border-color: rgb(255 255 255 / 55%);
    }

    .font-weight-lighter {
        font-weight: 300;
        opacity: .5;
        font-size: 15px;
    }

    del {
        color: #FF7F7F;
        font-size: 18px;
    }
}

.fz-25 {
    font-size: 25px;
}



.rating {
    display: flex;
    width: 100%;
    justify-content: center;
    overflow: hidden;
    flex-direction: row-reverse;
    height: 200px;
    position: relative;
    margin-top: -10px;
    margin-bottom: 25px;
}
.rating-p {
    position: absolute;
    top: 100px;
    font-size: 20px;
    font-weight: 500;
}
.rating-0 {
    filter: grayscale(100%);
}
.rating > input {
    display: none;
}
.rating > label {
    cursor: pointer;
    width: 40px;
    height: 40px;
    margin-top: auto;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23e3e3e3' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 76%;
    transition: .3s;
}
.rating > input:checked ~ label,
.rating > input:checked ~ label ~ label {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23fcd93a' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
}
.rating > input:not(:checked) ~ label:hover,
.rating > input:not(:checked) ~ label:hover ~ label {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23d8b11e' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
}

.emoji-wrapper {
    width: 100%;
    text-align: center;
    height: 100px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
}
.emoji-wrapper:before,
.emoji-wrapper:after {
    content: "";
    height: 15px;
    width: 100%;
    position: absolute;
    left: 0;
    z-index: 1;
}
.emoji-wrapper:before {
    top: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 35%, rgba(255, 255, 255, 0) 100%);
}
.emoji-wrapper:after {
    bottom: 0;
    background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 35%, rgba(255, 255, 255, 0) 100%);
}
.emoji {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: .3s;
}
.emoji > svg {
    margin: 15px 0;
    width: 70px;
    height: 70px;
    flex-shrink: 0;
}

#rating-1:checked ~ .emoji-wrapper > .emoji {
    transform: translateY(-100px);
}
#rating-2:checked ~ .emoji-wrapper > .emoji {
    transform: translateY(-200px);
}
#rating-3:checked ~ .emoji-wrapper > .emoji {
    transform: translateY(-300px);
}
#rating-4:checked ~ .emoji-wrapper > .emoji {
    transform: translateY(-400px);
}
#rating-5:checked ~ .emoji-wrapper > .emoji {
    transform: translateY(-500px);
}

.feedback {
    background-color: #fff;
    width: 100%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
}


.rating-modal {

    button.close {
        opacity: 1;

        span {
            font-weight: 500;
            font-size: 31px;
            opacity: 1;
            color: #4f6177;
        }
    }

    .modal-dialog {
        max-width: 550px;
    }

    .modal-body {
        padding: 1rem 3rem 2rem;

        @media (max-width: 500px) {
            padding: 1rem 1rem 2rem;
        }
    }

    .modal-header {
        background-color: #F4F5F8;
    }

    textarea.form-control {
        resize: none;
        height: 110px;
        border-radius: 3px;

        &::placeholder {
            opacity: .5;
            font-size: 14px;
        }
    }
}

.send-question-btn {
    margin-top: -45px;
    padding: 0;
    margin-right: 28px;

    svg path {
        fill: #838383;
    }

    &:hover svg path {
        fill: #676767;
    }
}

.student-feedback-reply {
    color: #000;
    text-decoration: underline;
}
.student-feedback-block {
    border-bottom: 0;
    padding: 20px;
}
.student-feedback-block-child-1 {
    padding: 20px;
    border-radius: 10px;
    margin-left: 50px;
}
.student-feedback-block-child-2 {
    padding: 20px;
    margin-left: 100px;
}

.replay-modal {
    .comment {
        max-height: 300px;
        overflow-y: auto;
        padding: 10px;
        background-color: #f2f2f2;
        border-radius: 7px;
    }

    .replay-form {
        padding: 10px;
        margin: 10px;

        strong {
            margin-top: 30px;
            display: block;
        }

        textarea {
            height: 200px;
            resize: none;
            margin-top: 30px;
        }
    }
}
