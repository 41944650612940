



html[dir="rtl"]{
    display: block;

    body{
        text-align: inherit;
    }
    .text-left, .dropdown-menu, .classes-accordion .card-header .btn {
        text-align: right;
    }
    .text-right{
        text-align: left;
    }
    .mr-auto{
        margin-left: auto;
        margin-right: 0;
    }
    .ml-auto {
        margin-right: auto;
        margin-left: 0;
    }
    .ml-1 {
        margin-left: 0;
        margin-right: 0.25rem;
    }
    .mr-2 {
        margin-left: 0.5rem;
        margin-right: 0;
    }
    .pl-4{
        padding-left: 0;
        padding-right: 1.5rem;
    }
    .pr-1, .px-1{
        padding-left: 0.25rem;
        padding-right: 0;
    }
    .navbar .navbar-collapse{

        @media (max-width: 1199px){
            right: inherit;
            left: 0;
        }
    }
    .navbar .navbar-nav{
        margin-left: 0;
        width: 100%;
        padding-right: 0;

        @media (max-width: 1199px){
            padding: 20px 20px 40px 20px;
        }
    }
    .navbar-toggler{
        padding-left: 0;
    }
    .nav-item.dropdown.d-none:not(.nav-item-user){
        margin-right: auto;
    }
    .navbar .nav-item.dropdown .dropdown-menu{

        @media (max-width: 1199px){
            margin-left: 0;
            margin-right: auto;
        }
    }
    .navbar-brand{
        margin-left: 1rem;
        margin-right: 0;
    }
    .navbar .menu-arrow{
        right: inherit;
        left: 10px;

        @media (max-width: 1199px){
            left: 17px;
        }
    }

    .navbar .links-group a[onclick="event.preventDefault()"] + .dropdown-toggle{
        right: inherit;
        left: 1px;
    }

    .navbar-nav .nav-item-user{

        @media (min-width: 1200px){
            margin-left: 0;
            margin-right: 15px;
        }
    }
    .navbar .nav-item-user{

        @media (max-width: 1199px){
            margin-left: 0;
            margin-right: 15px;
        }
    }
    .navbar .btn-1{
        margin: 0 15px 0 0;

        @media (max-width: 1199px){
            margin: 15px 0 0 0;
        }
    }
    .footer-links{
        padding: 0;
    }
    .dropdown-menu-right{
        right: inherit;
        left: 0;
        min-width: inherit;
    }
    .toggle-password{
        left: 15px;
        right: inherit;
    }
    .inp-promo{
        border-radius: 0 2em 2em 0;
    }
    .btn-promo-submit{
        border-radius: 2em 0 0 2em;
    }
    .bundle-section-price-item:first-child{
        border-right: 0;
        padding-left: 40px;
        padding-right: 0;
    }

    .profile-nav-link{
        padding: 12px 35px 12px 15px;
    }
    .profile-nav-link .menu-arrow{
        left: 15px;
        right: inherit;
    }
    .profile-nav-collapse .profile-nav-link b{
        margin-left: 10px;
        margin-right: 0;
    }
    .profile-notification-table tbody td:nth-child(2){

        @media (max-width: 575px){
            padding-left: 0.6rem;
            padding-right: 0;
        }
    }
    //.profile-nav-collapse{
    //    padding-right: 77px;
    //    padding-left: 0;
    //
    //    @media (max-width: 1240px){
    //        padding-right: 40px;
    //    }
    //    @media (max-width: 767px){
    //        padding-right: 50px;
    //    }
    //}
    .user-noty-mobile{
        margin: 0 auto 0 10px;
    }
    .edit-page-builder{
        left: 50px;
        right: inherit;
    }
    .home-course-section.home-course-section-slider .home-course-item{
        text-align: right;
    }
    .basic-header{
        padding-right: 9vw;
        padding-left: 0;

        @media (max-width: 991px){
            padding-right: 4vw;
        }
    }
    .basic-header-img > img, .landing-header-img > img, .theme-3-header-img > img, .theme-3-section-3-item > img,.theme-3-section-4-left-img{
        transform: scale(-1, 1);
    }
    .basic-head-title-img{
        left: inherit;
        right: 0;
    }
    .basic-header-text{
        padding-left: 1.6em;
        padding-right: 0;
    }

    .basic-section-2-right{
        margin-right: auto;
        margin-left: 0;
    }
    .basic-section-2-block{
        margin-right: auto;
        margin-left: 0;
    }
    .section-2-basic-oval{
        left: inherit;
        right: 0;
    }
    .home-course-author img{
        margin-right: 0;
        margin-left: 8px;
    }
    .basic-section-5-item-author img{
        margin-right: 0;
        margin-left: 13px;
    }
    .home-course-price p{
        padding-right: 0;
        padding-left: 10px;
    }
    .basic-landing-header{
        padding-right: 9vw;
        padding-left: 0;

        @media (max-width: 767px){
            padding: 0 20px;
        }
    }
    .basic-landing-header-text{
        padding-left: 1.6em;
        padding-right: 0;

        @media (max-width: 767px){
            padding-left: 0;
        }
    }
    .basic-landing-2-left{
        padding: 8.8% 11.5% 13% 0;
    }
    .basic-landing-2-right{
        margin-left: 0;
        margin-right: auto;
    }
    .basic-landing-3-right{
        padding: 8.8% 0 13% 11.5%;
    }
    .basic-landing-3-left{
        margin-left: auto;
        margin-right: 0;
    }
    .landing-header{

        @media (min-width: 768px){
            padding-left: 0;
            padding-right: 24px;
        }
    }
    .landing-header-text{

        @media (min-width: 768px){
            padding-left: 24px;
            padding-right: 0;
        }
    }
    .quize-list .slick-slider .slick-prev{
        right: -15px;
        left: auto;
    }
    .quize-list .slick-slider .slick-next{
        right: auto;
        left: -15px;
    }
    .first-live-item-right{
        border-left: none;
        border-right: solid 1px #E3E3E3;
        padding-left: 0;
        padding-right: 12px;

        h2{
            text-align: right;
        }
    }
    .live-lessons-price span{
        margin-left: 12px;
        margin-right: 0;
    }
    .first-live-item-left{
        padding-left: 9px;
        padding-right: 0;
    }
    .live-show-head-img:after{
        transform: rotate(180deg);
    }
    .live-show-right .icon-live{

        @media (max-width: 767px){
            margin-right: 0;
            margin-left: 15px;
        }
    }
    .product-container-wrapper .detailsBox{

        @media (min-width: 768px){
            padding-right: 24px;
        }
    }
    .icon-live-circle{
        margin-right: 0;
        margin-left: 6px;
    }
    .live-courses-included .faq-accordion .card-header .btn::after{
        right: inherit;
        left: 15px;
    }
    .live-courses-included .faq-accordion .card-header .btn > .card-title{
        padding-left: 55px;
        padding-right: 16px;
    }
    .included-live-show-day-mount{
        padding-right: 10px;
        padding-left: 5px;
        border-right: none;
        border-left: solid 5px #ffffff;
    }
    .live-spekars figure figcaption{
        padding-left: 0;
        padding-right: 32px;

        @media (max-width: 575px){
            padding-right: 0;
        }
    }
    .faq-accordion .card-header .btn{
        text-align: right;
    }
    .faq-accordion .card-header .btn span{
        padding-left: 40px;
        padding-right: 0;
    }
    .faq-accordion .card-header .btn:after{
        right: inherit;
        left: 0;
    }
    .live-courses-included .faq-accordion .card-header .btn > .card-title span{
        padding-left: 0;
    }
    .free-info{
        left: 10px;
        right: inherit;
    }
    .course-author img{
        margin-left: 6px;
        margin-right: 0;
    }
    .course-price span del{
        margin-left: 12px;
        margin-right: 0;
    }
    .course-single-head-left .star-block{
        padding-right: 0;
    }
    .course-single-head-left{
        padding: 20px 11vw 20px 20px;

        @media (max-width: 1199px){
            padding: 20px 50px 20px 20px;
        }
        @media (max-width: 767px){
            padding: 20px 20px 20px 20px;
        }
    }
    .classes-accordion .card-header .btn:after{
        right: 12px;
        left: inherit;
    }
    .accordion-button .btn{
        text-align: right;
    }
    .accordion-button .btn .menu-arrow{
        left: 12px;
        right: inherit;
    }
    .accordion-button .btn .section-title{
        padding-left: 30px;
        padding-right: 0;
    }
    .classes-accordion .card-header .btn{
        padding: 0.8rem 43px 0.8rem 1.2rem;

        @media (max-width: 767px){
            padding: 20px 30px 20px 10px;
        }
    }
    .episode-title{
        padding-right: 0;
        padding-left: 15px;

        @media (max-width: 767px){
            margin-left: auto;
            margin-right: 0;
            padding-left: 0;
            padding-right: 10px;
        }
    }
    .episode-count{
        margin-right: 0;
        margin-left: 10px;

        @media (max-width: 767px){
            left: 10px;
            right: inherit;
            margin-left: 0;
        }
    }
    .episode-time{
        text-align: left;
        direction: ltr;

        @media (max-width: 767px){
            left: 10px;
            right: inherit;
        }
    }
    .classes-accordion .card-body{
        padding: 10px 43px 10px 16px;

        @media (max-width: 575px){
            padding: 8px 12px 8px 10px;
        }
    }
    .classes-accordion .card-body > img{
        margin-left: 15px;
        margin-right: 0;
    }
    .classes-accordion .card-body .episode-time{
        margin-left: 13px;
        margin-right: 0;
    }
    .classes-accordion .card-body .episode-title{

        @media (max-width: 575px){
            padding-left: 12px;
            padding-right: 0;
        }
    }
    .blog-author img{
        margin-right: 0;
        margin-left: 19px;
    }
    .print-down a{
        margin-left: 0;
        margin-right: 20px;
    }
    .print-down a img{
        margin-right: 0;
        margin-left: 5px;
    }
    .secondary-contact-bg{
        left: 2vw;
        right: inherit;

        @media (max-width: 1199px){
            left: 0;
        }
    }

    .profile-menu{
        left: inherit;
        right: 0;
    }
    .profile-container{
        margin-right: auto;
        margin-left: 0;

        @media (max-width: 767px){
            padding-right: 65px;
            padding-left: 15px;
        }
    }
    .profile-nav-link{

        @media (max-width: 767px){
            padding: 11px 14px 11px 11px;
        }
    }
    .profile-nav-link img, .profile-nav-link svg{
        margin-left: 20px;
        margin-right: 0;

        @media (max-width: 1240px){
            margin-left: 15px;
        }
    }
    .open-menu-filter:after{
        transform: rotate(-45deg);
        right: 14px;
    }
    .profile-menu-open .open-menu-filter:after{
        transform: rotate(135deg);
    }
    .nav-tabs .nav-item .nav-link.mr-5{
        margin-right: 0;
        margin-left: 3rem;
    }
    .edit-img img{
        margin-left: 7px;
        margin-right: 0;
    }
    .btn-rating img {
        margin-right: 0;
        margin-left: 7px;
    }
    .modal-header .close{
        margin: -1rem auto -1rem -1rem;
    }
    .video-info-block{
        right: inherit;
        left: 0;
        border-right: solid 1px #d6d6d6;
        border-left: none;
    }
    .video-teacher-info{

        @media (min-width: 992px) {
            padding: 0 10px 10px 0 !important;
        }
    }
    .correct-answer:after{
        left: 10px;
        right: inherit;
    }
    .play-ul li a > img{
        margin-left: 10px;
        margin-right: 0;
    }
    .play-ul li a .play-title{
        margin-left: 8px;
        margin-right: 0;
        text-align: right;
    }
    .play-ul li a .play-time{
        margin-left: 20px;
        margin-right: 0;
    }
    .play-ul .free-episode{
        right: inherit;
        left: 6px;
    }
    .openCloseToggle{
        left: 395px;
        right: inherit;
        margin-left: 0;
        margin-right: -30px;
        border-radius: 0 6px 6px 0;
    }
    .video-info-block.closed{
        left: -380px;
    }
    .openCloseToggle.closed{
        left: 0;
        right: inherit;
    }
    .btn-back{
        direction: ltr;
        right: 17px;
        left: inherit;
    }
    .toggle-block{
        right: inherit;
        left: 12px;
    }
    .nextId{
        left: 410px;
        right: inherit;
        margin-left: 0;
        transform: translateY(-50%) rotate(360deg);

        @media (max-width: 991px){
            left: 0;
        }
    }
    .nextId.closed, body.position_mode_bottom .nextId{
        left: 0;
        right: inherit;
    }
    .previousId{
        left: inherit;
        right: 0;
        transform: translateY(-50%) rotate(180deg);
    }
    .episode-file-item p, .episode-file-item .link-blue{
        padding-left: 0;
        padding-right: 1rem;
    }
    .episode-file-item .link-blue img{
        margin-right: 0;
        margin-left: 0.25rem;
    }
    .file-separator{
        margin-left: 0;
        margin-right: 25px;
    }
    body.position_mode_bottom .openCloseToggle.closed{
        left: 50%;
        right: auto;
    }

    .text-404{

        @media (min-width: 768px){
            padding-right: 5vw;
            padding-left: 0;
        }
        @media (min-width: 1500px){
            padding-right: 10vw;
        }

    }
    .theme-3-landing-header{

        @media (min-width: 768px){
            padding-right: 6vw;
            padding-left: 0;
        }
    }
    .theme-3-landing-header-text{

        @media (min-width: 768px){
            padding-left: 1em;
            padding-right: 0;
        }
    }
    .theme-3-header-text{

        @media (min-width: 768px){
            margin-left: auto;
            margin-right: 0;
            padding-left: 1.6em;
            padding-right: 0;
        }
    }
    .theme-3-section-4-right{

        @media (min-width: 768px){
            margin-left: 0;
            margin-right: auto;
        }
    }
    .theme-4-header-text{
        right: 10vw;
        left: inherit;

        @media (max-width: 1199px){
            right: 7vw;
        }
        @media (max-width: 767px){
            right: 24px;
        }
    }
    .theme-4-service-item > p{
        text-align: right;
    }
    .theme-4-header img{
        transform: scale(-1, 1)
    }
    .theme-4-feedback-container .text-right >img, .theme-5-header-img > img, .theme-6-header-img > img, .theme-6-advantages-img{
        transform: scale(-1, 1);
    }
    .theme-4-feedback-right{

        @media (min-width: 768px){
            padding-right: 30px;
            padding-left: 0;
        }
        > img{
            transform: scale(-1, 1);
        }
    }
    .theme-4-feedback-right-item{

        @media (min-width: 768px){
            padding-right: 57px;
            padding-left: 0;
        }
    }
    .theme-4-feedback-left{

        @media (min-width: 768px){
            padding-right: 112px;
            padding-left: 0;
        }
    }
    .theme-4-feedback-blue{

        @media (min-width: 768px){
           right: 0;
            left: inherit;
        }
    }
    .theme-4-trener-bg{
        left: 0;
    }
    .theme-4-landing-header-text{

        @media (min-width: 768px){
            padding: 0 8vw 0 20px;
        }
        @media (min-width: 768px) and (max-width: 991px){
            padding: 0 5vw 0 20px;
        }
    }
    .theme-5-header{

        @media (min-width: 768px){
            padding-right: 13.75vw;
            padding-left: 0;
        }
        @media (min-width: 768px) and (max-width: 1280px){
            padding-right: 40px;
        }
    }
    .theme-5-header-text{

        @media (min-width: 768px){
            padding-left: 1.6em;
            padding-right: 0;
            margin-left: auto;
            margin-right: 0;
        }
    }
    .theme-5-section-4-img{

        @media (min-width: 768px){
            border-radius: 0 19.5vw;
        }
    }
    .theme-5-section-4-text{

        @media (min-width: 768px){
            border-radius: 0 0 19.5vw;
        }
    }
    .theme-5-section-6-item-user-name{

        @media (min-width: 768px){
            padding-left: 0;
            padding-right: 20px;
        }
    }
    .theme-5-landing-header-text, .theme-6-landing-header-text{

        @media (min-width: 768px){
            padding: 0 8vw 0 20px;
        }
        @media (min-width: 768px) and (max-width: 991px){
            padding: 0 5vw 0 20px;
        }
    }
    .theme-6-header-text{
        padding-right: 5.5vw;

        @media (min-width: 1199px){
            padding-right: 13vw;
            padding-left: 0;
        }
    }
    .theme-6-feedback-left-text{

        @media (max-width: 767px) {
            padding-right: 12px;
            padding-left: 0;
        }
    }
    .theme-6-advantages-text{
        @media (min-width: 768px){
            padding: 24px 24px 6px 0;
        }
    }
    .theme-6-advantages-text > hr{
        margin-left: auto;
        margin-right: 0;
    }
    .theme-6-h2 span{
        margin-right: 0;
        margin-left: 10px;
    }
    .theme-6-teachers-container > h3 + hr{
        margin-left: auto;
        margin-right: 0;
    }
    .theme-7-header{
        padding-right: 24px;
        padding-left: 0;

        @media (max-width: 767px){
            padding-right: 0;
        }
    }
    .theme-7-header-text{
        padding-left: 15px;
        padding-right: 15px;

        @media (min-width: 768px){
            padding-left: 4vw;
            padding-right: 0;
        }
    }
    .theme-7-prof-container > h2{
        text-align: right;
    }
    .theme-7-prof-bg, .theme-7-student-img > img, .theme-7-header-img > img, .theme-8-special-bg{
        transform: scale(-1, 1);
    }
    .theme-7-student-container{

        @media (min-width: 768px){
            padding: 0 12vw 1vw 0;
        }
    }
    .theme-7-landing-header-text{

        @media (min-width: 768px){
            padding: 18px 8.7vw 0 20px;
        }
        @media (min-width: 768px) and (max-width: 991px){
            padding: 0 5vw 0 20px;
        }
    }
    .theme-8-header-right{

        @media (min-width: 768px){
            padding: 5.15vw 0 4.2vw 9.5vw;
        }
        @media (min-width: 768px) and (max-width: 1199px){
            padding: 5.15vw 0 4.2vw 5vw;
        }
    }
    .theme-8-header-right-img{

        @media (min-width: 768px){
            margin-left: 0;
            margin-right: -46px;
            border-radius: 0 0 0 211px;
        }
    }
    .theme-8-header-right-img img{

        @media (min-width: 768px){
            border-radius: 205px 0 0 205px;
        }
    }
    .theme-8-header-left{

        @media (min-width: 768px){
            padding: 8.6vw 11vw 4.65vw 70px;
        }
        @media (min-width: 768px) and (max-width: 1199px){
            padding: 8.6vw 5vw 4.65vw 70px;
        }
    }
    .theme-8-why-item-text{
        padding-left: 0;
        padding-right: 24px;
    }

    .theme-8-special{

        @media (min-width: 768px){
            padding: 3.1vw 3vw 3.1vw 20px;
        }
        @media (min-width: 1200px){
            padding: 3.1vw 10vw 3.1vw 20px;
        }
    }
    .theme-8-people-left{

        @media (min-width: 768px){
            padding-right: 11vw;
            padding-left: 20px;
        }
        @media (min-width: 768px) and (max-width: 1199px){
            padding-right: 3vw;
        }
    }
    .theme-8-people-left-info{
        padding-right: 20px;
        padding-left: 0;
    }
    .theme-8-people-right{

        @media (min-width: 768px){
            padding-left: 11vw;
            padding-right: 5.3vw;
        }
        @media (min-width: 768px) and (max-width: 1199px){
            padding-left: 3vw;
            padding-right: 1.3vw;
        }
    }
    .theme-8-landing-header-text{

        @media (min-width: 768px){
            padding: 15px 10vw 20px 20px;
        }
        @media (min-width: 768px) and (max-width: 1199px){
            padding: 15px 5vw 20px 20px;
        }
    }
    .theme-9-landing-header-text, .theme-10-landing-header-text{

        @media (min-width: 768px){
            padding: 32px 7.6vw 0 5vw;
        }
        @media (min-width: 768px) and (max-width: 991px){
            padding: 30px 4vw 0 20px;
        }
    }
    .theme-10-home-about-left{

        @media (min-width: 768px){
            padding-left: 20px;
            padding-right: 0;
        }
    }
    .theme-10-home-about-right{

        @media (max-width: 767px){
            margin-left: 0;
            margin-right: auto;
        }
    }
    .theme-10-home-about-right-border{
        left: -24px;
        right: inherit;
    }
    .theme-11-header-right{

        @media (min-width: 768px){
            padding: 5.15vw 0 6.7vw 6.5vw;
        }
        @media (min-width: 768px) and (max-width: 1199px){
            padding: 5.15vw 0 4.2vw 5vw;
        }
    }
    .theme-11-header-right-img{

        @media (min-width: 768px){
            margin-left: 0;
            margin-right: -28px;
        }
    }
    .theme-11-header-right-img img{

        @media (min-width: 768px){
            border-radius: 13.5vw 0 0 0;
        }
    }
    .theme-11-header-left{

        @media (min-width: 768px){
            padding: 6vw 11.25vw 2.65vw 50px;
        }
        @media (min-width: 768px) and (max-width: 1199px){
            padding: 6vw 4.25vw 2.65vw 50px;
        }
    }
    .theme-11-why-item-text{
        padding-left: 0;
        padding-right: 24px;

        @media (max-width: 767px){
            padding-right: 20px;
        }
    }
    .theme-11-special{

        @media (min-width: 768px){
            padding: 3.1vw 3vw 3.1vw 20px;
        }
        @media (min-width: 1200px){
            padding: 3.1vw 10vw 3.1vw 20px;
        }
    }
    .theme-11-people-left{

        @media (min-width: 768px){
            padding-right: 11.4vw;
            padding-left: 20px;
        }
        @media (min-width: 768px) and (max-width: 1199px){
            padding-right: 3vw;
        }
    }
    .theme-11-people-right{

        @media (min-width: 768px){
            padding-left: 11vw;
            padding-right: 5.3vw;
        }
        @media (min-width: 768px) and (max-width: 1199px){
            padding-left: 3vw;
            padding-right: 1.3vw;
        }
    }
    .theme-11-landing-header-text{

        @media (min-width: 768px){
            padding: 15px 10vw 20px 20px;
        }
        @media (min-width: 768px) and (max-width: 1199px){
            padding: 15px 5vw 20px 20px;
        }
    }
    .webinar-time-item{
        margin-right: 0;
        margin-left: 20px;
    }
    .paypal-course-item-info{

        @media (min-width: 551px){
            padding-left: 0;
            padding-right: 20px;
        }
    }
    .custom-checkbox-glogal input + label{
        padding: 0 2em 0 0;
    }
    .custom-checkbox-glogal input + label::before{
        left: inherit;
        right: 0;
    }
    .custom-checkbox-glogal input + label::after{
        left: inherit;
        right: 3px;
    }
    .custom-radio-glogal input[type=radio] + label{
        padding: 0 2em 0 0;
    }
    .custom-radio-glogal input[type=radio] + label::before{
        left: inherit;
        right: 0;
    }
    .custom-radio-glogal input[type=radio] + label::after{
        left: inherit;
        right: 4px;
    }
    .live-show-head{
        padding: 4.8vw 18vw 4.8vw 4.8vw;

        @media (max-width: 991px){
            padding: 4.8vw 9vw 4.8vw 4.8vw;
        }
        @media (max-width: 767px){
            padding: 15px 15px 30px;
        }
    }
    .live-share-social-block{
        left: inherit;
        right: 0;
    }
    .live-share-social-block a{

        @media (min-width: 768px){
            border-radius: 6px 0 0 6px;
        }
    }
    .product-container-wrapper .detailsBox .title{
        text-align: right;
    }
    .quiz-container .btn.p-2 {
        transform: rotate(180deg);
    }
    .answer-text{
        padding: 2px 40px 2px 60px;

        @media (max-width: 767px){
            padding: 2px 20px 2px 60px;
        }
    }
    .quiz-finish-block .correct-answer:after{
        left: 25px;

        @media (max-width: 767px){
            left: 10px;
        }
    }
    .wrong-answer:after, .correct-answer-but-not-set:after{
        left: 25px;
        right: inherit;

        @media (max-width: 767px){
            left: 10px;
        }
    }
    .answer-img {
        padding: 10px 40px 10px 60px;

        @media (max-width: 767px){
            padding: 10px 20px 10px 60px;
        }
    }
    .faq-accordion .card-body p{
        text-align: right!important;
    }
    .course-share-social-block{
        left: initial;
        right: 0;

        @media (max-width: 767px){
            left: 10px;
            right: inherit;
        }
    }
    .course-single-head-left p{
        direction: ltr;
    }
    .course-share-social-block a{

        @media (min-width: 768px){
            border-radius: 6px 0 0 6px;
        }
    }
    .course-share-social-block a:hover span, .course-share-social-block button:hover span{
        margin-left: 20px;
        margin-right: 0;
    }
    .landing-right{

        @media (min-width: 768px){
            padding: 12px 24px 12px 0;
        }
    }
    .landing-container-reverse .landing-right{

        @media (min-width: 768px){
            padding: 12px 0 12px 24px;
        }
    }
    .theme-12-5-item-2{

        @media (min-width: 1160px){
            margin-left: 0;
            margin-right: auto;
        }
    }
    .theme-12-5 .home-h2{

        @media (min-width: 768px){
            text-align: right;
        }
    }
    [data-accordions] .accordion{
        padding: 7px 0 7px 34px;
    }
    [data-accordions] .accordion svg{
        right: inherit;
        left: 0;
    }
    .products-container-wrapper .products-container .detailsBox{
        text-align: right;
    }
    .theme-11-people-left-info{
        padding-left: 0;
        padding-right: 20px;

        @media (max-width: 991px){
            padding-right: 15px;
        }
    }
    .theme-11-people-left h2{
        text-align: right;
    }
    .theme-13-1-left{

        @media (min-width: 768px){
            padding-right: 0;
            padding-left: 24px;
        }
    }
    .theme-13-2-right{

        @media (min-width: 768px){
            padding-right: 24px;
            padding-left: 0;
        }
    }
    .theme-13-4-left{

        @media (min-width: 768px){
            padding-right: 0;
            padding-left: 24px;
        }
    }
    .theme-13-5-right{

        @media (min-width: 768px){
            padding-right: 24px;
            padding-left: 0;
        }
    }
    .theme-13-6-item > img{
        margin-right: 0;

        @media (min-width: 768px){
            margin-right: 0;
            margin-left: 24px;
        }
    }
    .theme-13-form .theme-all-form-bg{

        @media (max-width: 767px){
            opacity: .4;
        }
    }
    .theme-13-form .theme-all-form-container .text-center{
        text-align: right;
    }
    .theme-13-6 .home-h2 img{
        margin-left: 18px;
        margin-right: 0;
    }
    .theme-14-1-bg{
        transform: scaleX(-1);
    }
    .theme-14-1-text{

        @media (min-width: 768px){
            margin-left: 0;
            margin-right: 11vw;
        }
    }
    .theme-14-3-img img:nth-child(3){
        right: inherit;
        left: 0;
    }
    .theme-14-3-text{

        @media (min-width: 768px){
            padding: 12px 24px 12px 0;
        }
    }
    .theme-14-6-text{

        @media (min-width: 768px){
            padding-right: 24px;
            padding-left: 0;
        }
    }
    .theme-14-2-item{

        @media (max-width: 767px){
            text-align: right;

            h4{
                padding-left: 0;
                padding-right: 20px;
            }
        }
    }
    .theme-14-7-text figure figcaption{
        padding-left: 0;
        padding-right: 15px;
    }
    .theme-14-7-quot{
        right: inherit;
        left: 24px;
    }
    .theme-14-8-item-text{
        padding-left: 0;
        padding-right: 15px;
    }


    .cookie-section > img{
        margin-left: 20px;
        margin-right: 0;
    }
    .cookie-section .btn{
        margin-left: 0;
        margin-right: 12px;
    }
    .cookie-section .btn-close{
        left: 15px;
        right: inherit;
    }

    .th-15-1{

        @media (min-width: 768px){
            padding: 17px 3.5vw 17px 17px;
        }
    }
    .th-15-1-text-content{

        @media (min-width: 1160px){
            padding: 2vw 8vw 2vw 2vw;
        }
    }
    .th-15-2-right, .th-15-3-text{

        @media (min-width: 768px){
            padding: 12px 36px 12px 0;
        }
    }
    .th-15-3-img > img:nth-child(3){
        right: inherit;
        left: 0;
    }
    .th-15-3-img > img:nth-child(2){
        margin-left: 0;
        margin-right: 95px;

        @media (max-width: 1159px) {
            margin-right: 25px;
        }
    }
    .th-15-6-item:nth-child(even){

        @media (min-width: 768px){
            margin-left: 0;
            margin-right: auto;
        }
    }
    .th-15-6 .home-h2 img{
        margin-right: 0;
        margin-left: 18px;
    }
    .th-15-6-item > img{
        margin-left: 24px;
        margin-right: 0;
    }
    .th-15-3-text ul{

        @media (max-width: 1159px){
            padding-right: 22px;
            padding-left: 0;
        }
    }
    .basic-section-3-container .landing-container-full-text,
    .basic-section-4-container-1 .landing-container-full-text,
    .basic-section-5-container-1 .landing-container-full-text,
    .home-static-container .landing-container-full-text,
    .home-static-container-2 .landing-container-full-text,
    .home-static-container-3 .landing-container-full-text,
    .theme-4-service .landing-container-full-text{

        @media (min-width: 768px){
            padding-left: 24px;
            padding-right: 0;
        }
    }
    .basic-section-3-container .landing-container-full-reverse .landing-container-full-text,
    .basic-section-4-container-1 .landing-container-full-reverse .landing-container-full-text,
    .basic-section-5-container-1 .landing-container-full-reverse .landing-container-full-text,
    .home-static-container .landing-container-full-reverse .landing-container-full-text,
    .home-static-container-2 .landing-container-full-reverse .landing-container-full-text,
    .home-static-container-3 .landing-container-full-reverse .landing-container-full-text,
    .theme-4-service .landing-container-full-reverse .landing-container-full-text{

        @media (min-width: 768px){
            padding-right: 24px;
            padding-left: 0;
        }
    }


    .th-16-1-bg{
        transform: scale(-1, 1)
    }
    .th-16-1-text{

        @media (min-width: 576px) {
            margin-right: 11.2vw;
            margin-left: 0;
        }
    }
    .th-16-3-img > img:nth-child(3){
        right: inherit;
        left: 0;
    }
    .th-15-3-text ul{
        padding-right: 20px;
        padding-left: 0;
    }
    .th-15-3-text{

        @media (min-width: 768px){
            padding: 12px 36px 12px 0;
        }
    }
    .th-16-6-container{
        padding-right: 60px;
        padding-left: 0;

        @media (max-width: 767px){
            padding-right: 0;
        }
    }
    .th-16-6-text{

        @media (min-width: 768px){
            padding: 12px 36px 12px 0;
        }
    }
    .th-16-6-text figcaption{
        padding-left: 0;
        padding-right: 36px;

        @media (max-width: 767px){
            padding-right: 12px;
        }
    }
    .th-16-7 .home-h2 img{
        margin-right: 0;
        margin-left: 18px;
    }
    .th-16-8-item-text{
        padding-left: 0;
        padding-right: 24px;

        @media (max-width: 1159px){
            padding-right: 15px;
        }
    }
    .th-16-6-text figure{

        @media (max-width: 767px){
            padding-left: 0;
            padding-right: 15px;
        }
    }

    .theme-17-1{

        @media (min-width: 768px) {
            padding-right: 0;
            padding-left: 24px;
        }
    }
    .theme-17-1-left{
        padding: 24px 24px 24px 0;

        @media (max-width: 767px){
            padding: 48px 24px 48px 0;
        }
        @media (min-width: 768px) {
            margin-right: auto;
            margin-left: -2%;
        }
    }
    .th-17-2-text{

        @media (min-width: 768px){
            margin-left: 0;
            margin-right: -10%;
        }
    }
    .th-17-2-text .home-h2{

        @media (min-width: 768px) and (max-width: 991px){
            padding-left: 0;
            padding-right: 12%;
        }
    }
    .th-17-2-img{

        @media (min-width: 768px){
            padding-right: 0;
            padding-left: 7.65%;
        }
    }
    .th-17-2-img img, .theme-17-4-right img{
        transform: scale(-1, 1);
    }
    .th-17-2-img:before{
        transform: rotate(-19.4deg);
        left: 21.5%;
        right: inherit;
    }
    .theme-17-5-title img{
        margin-left: 20px;
        margin-right: 0;

        @media (max-width: 767px){
            margin-left: 10px;
        }
    }

    .live-section .first-live-container.slick-slider .slick-prev,
    .home-course-section-container.slick-slider .slick-prev{
        right: -15px;
        left: inherit;

        @media (max-width: 1159px){
            right: -10px;
        }
        @media (max-width: 767px){
            right: -13px;
        }
    }
    .live-section .first-live-container.slick-slider .slick-next,
    .home-course-section-container.slick-slider .slick-next{
        left: -15px;
        right: inherit;

        @media (max-width: 1159px){
            left: -10px;
        }
        @media (max-width: 767px){
            left: -13px;
        }
    }
    .live-section .first-live-container.slick-slider .slick-next:before,
    .home-course-section-container.slick-slider .slick-next:before{
        transform: rotate(133deg);
    }
    .live-section .first-live-container.slick-slider .slick-prev:before,
    .home-course-section-container.slick-slider .slick-prev:before{
        transform: rotate(315deg);
    }
    .overdue-bg{
        transform: scale(-1, 1);
    }
    .overdue-text{

        @media (min-width: 768px){
            padding-left: 0;
            padding-right: 14vh;
        }
    }
    .coaching-item-info-left:before{
        left: 0;
        right: inherit;
    }
    .coaching-item-price b{
        margin-right: 0;
        margin-left: 10px;
    }
    .coaching-item-price del{
        margin-left: 8px;
        margin-right: 0;
    }
    .coaching-spekars figure figcaption{

        @media (min-width: 576px){
            padding-right: 32px;
            padding-left: 0;
        }
    }
    .coaching-show-head-img:after{
        background: linear-gradient(272deg, #000 0%, rgba(7, 7, 7, 0.92) 20.07%, rgba(11, 11, 11, 0.87) 29.55%, rgba(11, 11, 11, 0.87) 34.91%, rgba(12, 12, 12, 0.86) 40.07%, rgba(18, 18, 18, 0.79) 45.08%, rgba(21, 21, 21, 0.75) 48.68%, rgba(21, 21, 21, 0.75) 50.76%, rgba(22, 22, 22, 0.75) 53.67%, rgba(23, 23, 23, 0.73) 58.08%, rgba(30, 30, 30, 0.64) 63.69%, rgba(33, 33, 33, 0.6) 68.17%, rgba(41, 41, 41, 0.51) 73.01%, rgba(47, 47, 47, 0.44) 82.07%, rgba(56, 56, 56, 0.33) 88.59%, rgba(61, 61, 61, 0.27) 91.52%, rgba(67, 67, 67, 0.2) 95.11%, rgba(76, 76, 76, 0.09) 99.28%, rgba(84, 84, 84, 0) 100%);

        @media (max-width: 767px){
            background: linear-gradient(272deg, #000 0%, rgba(7, 7, 7, 0.92) 20.07%, rgba(11, 11, 11, 0.87) 29.55%, rgba(11, 11, 11, 0.87) 34.91%, rgba(12, 12, 12, 0.86) 40.07%, rgba(18, 18, 18, 0.79) 45.08%, rgba(21, 21, 21, 0.75) 60.68%, rgba(21, 21, 21, 0.75) 50.76%, rgba(22, 22, 22, 0.75) 68.67%, rgba(23, 23, 23, 0.73) 89.08%, rgba(30, 30, 30, 0.64) 99.69%, rgba(33, 33, 33, 0.6) 68.17%, rgba(41, 41, 41, 0.51) 73.01%, rgba(47, 47, 47, 0.44) 82.07%, rgba(56, 56, 56, 0.33) 88.59%, rgba(61, 61, 61, 0.27) 91.52%, rgba(67, 67, 67, 0.2) 95.11%, rgba(76, 76, 76, 0.09) 99.28%, rgba(84, 84, 84, 0) 100%);
        }
    }
    .coaching-show-head{
        padding: 4.8vw 18vw 4.8vw 4.8vw;

        @media (max-width: 991px){
            padding: 4.8vw 9vw 4.8vw 4.8vw;
        }
        @media (max-width: 767px){
            padding: 25px 20px 50px;
        }
    }
    .student-feedback-block figcaption{
        padding-left: 0;
        padding-right: 12px;
    }
    .membership-item-tenant-list li svg{
        margin-right: 0;
        margin-left: 8px;
    }
    .membership-item-tenant-head p del{
        margin-right: 0;
        margin-left: 5px;
    }
    .membership-item-tenant-head h2{
        padding-right: 0;
        padding-left: 15px;
    }
    .bundles-item-price{
        padding-right: 0;
        padding-left: 15px;
        direction: ltr;
    }
    .product-single-right{

        @media (min-width: 1024px) {
            padding-left: 0;
            padding-right: 40px;
        }
    }
    .detailsBox .footer .btn{
        margin-right: auto;
        margin-left: 0;
    }
    .detailsBox .priceBox {
        direction: ltr;
    }
    .inStock img{
        margin-right: 0;
        margin-left: 6px;
    }
    .course-price p{
        direction: ltr;
        padding-right: 0;
        padding-left: 10px;
    }
    .new-courses-content, .new-products-content{

        @media (min-width: 992px) {
            padding-left: 0;
            padding-right: 24px;
        }
    }
    .course-price del{
        margin-right: 0;
        margin-left: 4px;
    }
    .new-pagination li.new-next a:after, .new-pagination li.new-next a:before, .new-pagination li.new-prev a:after, .new-pagination li.new-prev a:before{
        transform: rotate(315deg);
    }
    .new-pagination li.new-next a:before, .new-pagination li.new-prev a:before{
        transform: rotate(224deg);
    }
    .detailsBox{
        text-align: right;
    }
    .detailsBox .priceBox .price{
        padding-right: 0;
        padding-left: 10px;
    }
    .new-filter-sort .dropdown-toggle strong{
        margin-right: 0;
        margin-left: 6px;
    }
    .session-icon-live{
        left: 12px;
        right: inherit;
    }
    .live-session-item-more{
        margin-left: 0;
        margin-right: auto;
    }
    .live-session-item-price{
        padding-right: 0;
        padding-left: 15px;
        direction: ltr;
    }
    .live-session-item-left{
        border-radius: 7px 0 0 0;
    }
    .session-icon-live i{
        margin-right: 0;
        margin-left: 6px;
    }
    .live-session-item-price del{
        margin-left: 4px;
        margin-right: 0;
    }
    .ngjs-course-price p{
        padding-right: 0;
        padding-left: 8px;
        direction: ltr;
    }
    .ngjs-course-price strong{
        margin-left: 0;
        margin-right: 6px;
    }
    .ngjs-course-author img{
        margin-right: 0;
        margin-left: 14px;
    }
    .ngjs-product-unavailable img, .ngjs-product-available img{
        margin-right: 0;
        margin-left: 6px;
    }
    .ngjs-product-item-price{
        padding-right: 0;
        padding-left: 10px;
        direction: ltr;
    }
    .ngjs-product-item-more{
        margin-left: 0;
        margin-right: auto;
    }
    .ngjs-price-1-list li img{
        margin-right: 0;
        margin-left: 9px;
    }
    .quiz-index-item h3{
        direction: ltr;
        text-align: right;
    }
    .btn-course-edit{
        right: inherit;
        left: 12px;
    }
    .coaching-show-head-content{

        .btn.btn-1.mr-1{
            margin-left: 0.25rem;
            margin-right: 0;
        }
    }


    [dir="ltr"]{
        display: none;
    }
    [dir="rtl"]{
        display: inherit;
    }
}
