
.live-sessions-container{
    max-width: 1076px;
    margin: 0 auto;
    padding: 5vh 20px 100px;
    position: relative;
    z-index: 1;
    min-height: calc(100vh - 60px);

    @media (min-width: 1500px) {
        max-width: 1428px;
    }
    @media (max-width: 1199px) {
        max-width: 724px;
    }
    @media (max-width: 767px){
        max-width: 372px;
    }
}
.live-sessions-content{
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .no-results, .no-results-completed{
        grid-column: span 3;
    }
}
.live-session-item-block{
    flex: 0 0 calc(33.333% - 13.34px);

    @media (min-width: 1500px) {
        flex: 0 0 calc(25% - 15px);
    }
    @media (max-width: 1199px){
        flex: 0 0 calc(50% - 10px);
    }
    @media (max-width: 767px){
        flex: 0 0 100%;
    }
    &.h-100{
        height: auto;
    }
}
.live-session-item{
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    transition: background-color .3s, color .3s, border .3s;
    border: 1px solid #eaebf4;
    color: #000000;
    background-color: #ffffff;
    height: 100%;
}
.live-session-img-block{
    height: 186px;
    position: relative;
}
.live-session-img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.live-session-item-left{
    min-width: 100px;
    max-width: 140px;
    padding: 7px 16px;
    border-bottom: solid 1px #D1D5DF;
    background-color: #ffffff;
    position: absolute;
    bottom: 0;
    border-radius: 0 7px 0 0;

    strong{
        font-weight: 600;
    }
}
.session-icon-live{
    border-radius: 17px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    padding: 5px 10px 5px 10px;
    background-color: #ffffff;
    color: #000000;
    border: solid 1px #d1d5df;
    white-space: nowrap;
    position: absolute;
    bottom: 12px;
    right: 12px;

    i{
        border-radius: 50%;
        width: 18px;
        height: 18px;
        border: 2px solid #D2D2D2;
        padding: 3px;
        display: inline-block;
        margin-right: 6px;
    }
}
.online{
    background-color: #D41A1A;
}
.offline{
    background-color: #419DBB;
}
.person{
    background-color: #3076DF;
}
.live-session-item-info{
    display: flex;
    flex-direction: column;
    height: calc(100% - 186px);
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;

    h2{
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 12px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 1.389;

        strong{
            font-weight: 600;
        }
    }
    p{
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 14px;
        margin-bottom: 12px;
    }
}
.live-session-item-speaker{
    white-space: nowrap;
    color: grey;
    width: 100%;
    display: flex!important;
    align-items: center;

    span{
        color: black;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 4px;
        font-weight: 500;
    }
}
.live-session-item-price-more{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: auto;
}
.live-session-item-price{
    padding-right: 15px;
    line-height: 1.5;
    white-space: nowrap;
    margin: 10px 0;

    del{
        opacity: .6;
        margin-right: 4px;
        font-size: 14px;
    }
    span{
        font-weight: 600;
        font-size: 16px;
    }
}
.live-session-item-more{
    margin-left: auto;
    white-space: nowrap;

    .btn{
        padding: 0.393em 1em;
    }
}
.live-session-button{
    margin-top: 35px;
    text-align: center;
}


.icon-live{
    border-radius: 17px;
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    padding: 3px 6px 3px 7px;
    //margin-bottom: 13px;
    background-color: #ffffff;
    border: solid 1px #7d7d7d;
    white-space: nowrap;
}
.icon-live-circle{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: solid 3px #ffffff;
    background-color: #D41A1A;
    margin-right: 6px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 47%);
}
.icon-live-offline{
    background-color: #12AFB5;
}
.icon-live-person{
    background-color: #3076DF;
}
.live-day-mount{

    p{
        font-size: 25px;
        margin-bottom: 2px;
    }
    h6{
        font-size: 13px;
    }
}


.first-live-item-right{
    width: calc(100% - 130px);
    border-left: solid 1px #E3E3E3;
    padding-left: 12px;
    display: flex;
    flex-direction: column;

    @media (max-width: 1157px){
        width: calc(100% - 105px);
        padding-left: 10px;
    }
    h2{
        font-size: 18px;
        line-height: 28px;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-align: left;

        @media (max-width: 1157px){
            font-size: 14px;
            line-height: 22px;
        }
    }
}

.live-lessons-price{

    span{
        font-size: 19px;
        margin-right: 12px;

        @media (max-width: 1157px){
            font-size: 17px;
        }
        i{
            font-style: normal;
        }
    }
    del{
        font-size: 16px;
        opacity: .62;

        @media (max-width: 1157px){
            font-size: 16px;
        }
        i{
            font-style: normal;
        }
    }
}


.completed-lessons {
    margin-top: 80px;
    border-top: 2px solid silver;
    padding-top: 20px;
}
.completed-lessons p{
    font-size: 20px;
}



.live-show-head{
    display: flex;
    align-items: center;
    background-color: #F4F5F8;

    @media (max-width: 991px){
        flex-direction: column;
        padding-top: 40px;
        padding-bottom: 50px;
    }
    @media (max-width: 767px) {
        flex-direction: column-reverse;
    }
}
.live-show-head-img{
    max-width: 990px;
    flex: 0 0 56%;
    text-align: center;

    @media (max-width: 991px){
        padding: 0 20px;
        max-width: 760px;
    }
    @media (max-width: 767px){
        flex: 0 0 100%;
    }
    > img{
        max-width: 100%;
        object-fit: contain;
        max-height: 40vw;

        @media (max-width: 991px){
            max-height: none;
        }
    }
}
.live-show-head-content{
    flex-grow: 1;
    padding: 28px 20px 28px 6.5vw;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @media (max-width: 991px){
        max-width: 760px;
        padding: 0 20px 30px 20px;
    }
    @media (max-width: 767px){
        padding: 20px 20px 0 20px;
    }
}
.live-show-head-content-text{

    @media (min-width: 1400px){
        min-width: 470px;
    }
}
.start-lesson{
    font-size: 16px;
}

.start-lesson-time{
    font-size: 12px;
    margin-top: 8px;

    @media (max-width: 767px){
        margin-top: 6px;
    }
}
.gap-10{
    gap: 10px;
}
.gap-6{
    gap: 6px;
}
.live-show-right{

    @media (max-width: 767px){
        display: flex;
        width: 100%;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 15px;

        .start-lesson-time{
            margin-top: 0;
            font-size: 16px;
        }
    }
    .icon-live{
        background-color: transparent;
    }
}
.show-icon-live-circle{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: solid 3px #ffffff;
    margin-right: 6px;
}
.live-show-day-mount{

    strong{
        font-weight: 500;
    }
    p{
        font-size: 32px;
        margin-bottom: 2px;

        @media (max-width: 767px){
            font-size: 28px;
        }
        b{
            font-weight: 500;
        }
    }
    h6{
        font-size: 18px;

        @media (max-width: 767px){
            font-size: 15px;
        }

        b{
            font-weight: 500;
        }
    }
}
.font-13{
    font-size: 13px;
}
.text-grey{
    color: #BCBCBC;
}
.live-show-speaker{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-top: 1px;

    @media (max-width: 767px){
        flex-wrap: wrap;
    }
    p{
        font-size: 26px;
        margin-bottom: 0;

        @media (max-width: 767px){
            font-size: 22px;
        }
    }
    del{
        font-size: 16px;
        opacity: 0.62;
        margin-left: 7px;
        margin-right: 5px;
    }
    &.mb-0{
        margin-bottom: 0;
    }
}
.live-show-left{

    h1{
        font-size: 20px;
        line-height: 1.4;
        margin-bottom: 10px;

        @media (max-width: 1199px){
            font-size: 18px;
        }
    }
}
.webinar-time{
    display: flex;
}
.webinar-time-item{
    text-align: center;
    margin-right: 20px;

    span{
        width: 45px;
        height: 45px;
        border-radius: 7px;
        border: solid 1px #cbcbcb;
        padding: 5px;
        font-size: 18px;
        display: inline-flex;
        line-height: 1.2;
        align-items: center;
        justify-content: center;
        margin-bottom: 3px;

        &#countdown_seconds{
            opacity: .5;
        }
    }

    i{
        display: block;
        font-style: normal;
        font-size: 13px;
    }
}

.live-show-container{
    max-width: 945px;
    margin:  0 auto;
    padding: 60px 15px;

    @media (max-width: 767px){
        padding: 40px 15px;
    }

    h2{
        font-size: 20px;
        margin-bottom: 24px;
    }
    p{
        margin-bottom: 30px;
        line-height: 1.7;
    }
}
.live-list{
    display: flex;
    flex-wrap: wrap;

    li{
        width: 50%;
        margin-bottom: 6px;
        padding: 0 35px;
        display: flex;

        @media (max-width: 991px){
            padding: 0 4px;
        }
        @media (max-width: 767px){
            width: 100%;
        }
    }
}
.list-arrow{
    display: inline-block;
    width: 8px;
    height: 12px;
    border: solid var(--color-1);
    border-width: 0 0.2em 0.2em 0;
    transform: rotate(45deg);
    margin-right: 16px;
    margin-top: 5px;
}
.live-courses-included{
    background-color: #F4F5F8;
    padding: 60px 0 60px;

    @media (max-width: 767px){
        padding: 40px 0 40px;
    }
    .faq-accordion{
        max-width: 945px;
        margin: 0 auto;
        padding: 0 15px;
        background-color: transparent;

        .card{
            border: none;
            box-shadow: none;
            border-radius: 4px !important;
            margin-top: 10px;
            margin-bottom: 0;
        }
        .card-body{
            padding: 5px 15px 16px 218px;
            background-color: #ffffff;

            @media (max-width: 767px){
                padding: 10px 15px 15px 15px;

                p{
                    font-size: 14px;
                }
            }
        }
        h3{
            font-size: 20px;
            margin-bottom: 20px;

            @media (max-width: 767px){
                margin-bottom: 30px;
            }
        }
        .card-header{
            padding: 0;
        }
        .card-header .btn{
            background-color: transparent;
            border-radius: 4px;
            display: flex;
            align-items: center;
            border-bottom: solid 1px #ffffff;
            letter-spacing: 0;

            &[aria-expanded=true]{
                background-color: #ffffff;
            }
            &::after{
                right: 15px;

                @media (max-width: 767px){
                    right: 10px;
                }
            }
        }
        .card-header .btn > .card-title{
            width: calc(100% - 200px);
            padding-left: 15px;
            padding-right: 50px;
            font-size: 16px;
            color: black;
            line-height: 1.4;
            margin: 15px 0;

            @media (max-width: 767px) {
                width: calc(100% - 120px);
                font-size: 14px;
                line-height: 1.5;
                padding: 5px 45px 5px 10px;
            }
            span{
                line-height: inherit;

                &.free-info {
                    background-color: var(--color-1);
                    color: #fff;
                    font-weight: 300;
                    right: -1px;
                    top: -1px;
                    padding: 2px 10px!important;
                    border-radius: 0 8px 0 8px;
                }
                @media (max-width: 767px){
                    padding-right: 0;
                    font-size: 14px;
                }
            }
        }
    }
}
.included-live-show-day-mount{
    width: 200px;
    border-right: solid 5px #ffffff;
    padding-left: 12px;
    padding-top: 5px;
    min-height: 91px;
    margin: 10px 0;
    border-right: 1px solid #d9d9d9;

    @media (max-width: 767px) {
        width: 120px;
        padding-left: 8px;

        .text-secondary{
            display: none!important;
        }
    }
    p{
        font-size: 16px;
        line-height: 1.1;
    }
    span{
        padding-right: 5px!important;
        font-size: 11px!important;
    }
    .rounded{
        padding: 5px;
        border-radius: 20px;
    }
    h6{
        font-size: 16px;
        margin-bottom: 6px;

        @media (max-width: 767px){
            font-size: 14px;
        }
    }
}
.live-session-upcoming, .live-session-finished{
    background-color: #3CC6A1;
    border-radius: 8px 8px 0 0;
    min-width: 114px;
    text-align: center;
    display: inline-block;
    position: relative;
    bottom: -10px;
    left: 3px;
    font-size: 12px;
    padding: 2px 10px;
}
.live-session-finished{
    background-color: #D7D8DC;
}
.free-info{
    position: absolute;
    right: 14px;
    top: 0;
    padding: 0!important;
    font-size: 14px!important;

    @media (max-width: 767px){
        right: 7px;
    }
}

.live-page-speakers{
    max-width: 945px;
    padding: 60px 15px 60px;
    margin: 0 auto;

    @media (max-width: 767px){
        padding: 40px 15px 40px;
    }

    h2{
        font-size: 20px;
        margin-bottom: 40px;
    }
}
.live-speakers{

    figure{
        margin-bottom: 34px;

        @media (max-width: 575px) {
            flex-direction: column;
            margin-bottom: 40px;
        }
        > img{
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;
        }
        figcaption{
            width: calc(100% - 140px);
            padding-left: 32px;

            @media (max-width: 575px){
                width: 100%;
                padding-left: 0;
                margin-top: 15px;
                text-align: center;
            }
            h3{
                font-size: 16px;
                margin-bottom: 11px;
            }
            p{
                line-height: 1.75;
                margin-bottom: 0;

                @media (max-width: 575px){
                    text-align: left;
                    line-height: 1.5;
                }
            }
        }
    }
}


