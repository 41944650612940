
.contact-page{
    min-height: calc(100vh - 312px);
}
.contact-left{
    width: 44%;
    background-color: #F6F6F7;
    padding: 4vw 20px 20px;

    @media (min-width: 1600px) {
        width: 55%;
    }
    @media (max-width: 991px){
        width: 50%;
    }
    @media (max-width: 767px){
        width: 100%;
        padding: 50px 20px;
    }
    h1, h2{
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 25px;

        @media (max-width: 767px){
            text-align: center;
        }
    }
    h2{
        font-size: 18px;
    }
    p{
        font-size: 19px;
        margin-bottom: 31px;

        @media (max-width: 767px){
            text-align: center;
            font-size: 18px;
        }
    }
}
.contact-left-container{
    max-width: 530px;
}
.address-table{
    margin-bottom: 50px;
    width: 100%;

    td{
        padding: 8px;
        vertical-align: top;
        white-space: nowrap;

        @media (max-width: 1299px) {
            font-size: 14px;
        }
        &:first-child{
            padding-left: 0;
            width: 35px;
            height: 43px;
        }
        &:last-child{
            padding-right: 0;
            white-space: normal;
        }
        a{
            color: black;

            &:hover{
                text-decoration: none;
                opacity: .7;
            }
        }
    }
}
.contact-secondary-social{

    @media (max-width: 767px){
        text-align: center;
    }
    a{
        margin-right: 9px;
        margin-bottom: 9px;
        display: inline-block;

        @media (max-width: 767px){
            margin: 0 5px 9px;
        }
        &:hover{
            text-decoration: none;
        }
    }
}
.contact-right{
    width: 56%;
    position: relative;
    background-color: #F6F6F7;
    border-top: solid 1px #f6f6f7;

    @media (min-width: 1600px) {
        width: 45%;
    }
    @media (max-width: 991px){
        width: 50%;
    }
    @media (max-width: 767px){
        width: 100%;
        order: -1;
    }
    form{
        max-width: 334px;

        @media (max-width: 991px){
            max-width: inherit;
        }
    }
    .form-group{
        margin-bottom: 8px;

        label{
            padding-left: 20px;
            margin-bottom: 5px;
        }
    }
    .form-control{
        background-color: #F6F6F7;
        border-color: #D0D1D6;
        border-radius: 20px;
        height: 39px;

        &:focus{
            box-shadow: none;
            outline: none;
            border-color: #f4b100;
        }
    }
    textarea.form-control{
        resize: none;
        height: 75px;
        border-radius: 14px;
    }
}
.secondary-contact-bg{
    position: absolute;
    bottom: 15px;
    z-index: 1;
    width: 31.35%!important;
    height: auto!important;
    right: 2vw;
    max-height: 90%;

    @media (max-width: 1199px) {
        right: 0;
    }
    @media (max-width: 991px){
        display: none;
    }
}
.contact-right-container{
    padding: 4vw;
    position: relative;
    background-color: #ffffff;
    min-height: 100%;

    h2{
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 25px;
        max-width: 57%;

        @media (max-width: 991px){
            max-width: inherit;
        }
        @media (max-width: 767px){
            text-align: center;
        }
    }
}

