
.coaching-section{
    max-width: 1076px;
    margin: 0 auto;
    padding: 5vh 20px 100px;
    position: relative;
    z-index: 1;
    min-height: calc(100vh - 60px);

    @media (min-width: 1500px) {
        max-width: 1428px;
    }
    @media (max-width: 1199px) {
        max-width: 724px;
    }
    @media (max-width: 767px){
        max-width: 372px;
    }
}
.coaching-section-container{
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .no-results{
        grid-column: span 3;
    }
}

.coaching-item{
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #eaebf4;
    flex: 0 0 calc(33.333% - 13.34px);

    @media (min-width: 1500px) {
        flex: 0 0 calc(25% - 15px);
    }
    @media (max-width: 1199px){
        flex: 0 0 calc(50% - 10px);
    }
    @media (max-width: 767px){
        flex: 0 0 100%;
    }
}
.coaching-item-block{
    color: black;
    background-color: #ffffff;
    display: block;
    box-shadow: 0 3px 6px rgb(0 0 0 / 17%);
    transition: all .2s;
    position: relative;
    height: 100%;
    overflow: hidden;
    border-radius: 4px 4px 0 0;

    &:hover{
        color: black;
        text-decoration: none;
        box-shadow: 0 3px 6px rgb(0 0 0 / 5%);
    }

    > img{
        height: 186px;
        width: 100%;
        object-fit: cover;
    }
}
.coaching-item-info{
    height: calc(100% - 186px);
}
.coaching-item-info-left{
    padding: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    flex: 0 0 auto;
    max-width: 180px;

    &:before{
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: #E3E4EC;
    }
    .icon-live{
        border: solid 1px #dbdbdb;
        font-size: 12px;
        margin-bottom: 12px;
    }
}
.coaching-item-label{
    position: absolute;
    top: 32px;
    right: -30px;
    background-color: #ffffff;
    transform: rotate(45deg);
    width: 150px;
    text-align: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
.coaching-day-mount{
    white-space: nowrap;

    p{
        color: #9294A6;
        font-size: 12px;
        margin-bottom: 0;
    }
}


.coaching-item-info-right{
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    flex: 1 1 auto;

    h2{
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-top: auto;
        margin-bottom: 12px;
    }
    .btn{
        font-size: 12px;
        padding: 0.4em 1.1em;
        margin-top: 5px;
    }
}
.coaching-item-price{

    b{
        font-weight: 600;
        margin-right: 10px;
    }
    del{
        color: #A4A4A4;
        font-size: 14px;
        margin-right: 8px;
    }
}

.coaching-show-head{
    display: flex;
    align-items: center;
    background-color: #F4F5F8;

    @media (max-width: 991px){
        flex-direction: column;
        padding-top: 40px;
        padding-bottom: 50px;
    }
    @media (max-width: 767px) {
        flex-direction: column-reverse;
    }
}
.coaching-show-head-img{
    max-width: 990px;
    flex: 0 0 56%;
    text-align: center;

    @media (max-width: 991px){
        padding: 0 20px;
        max-width: 760px;
    }
    @media (max-width: 767px){
        flex: 0 0 100%;
    }
    > img{
        max-width: 100%;
        object-fit: contain;
        max-height: 40vw;

        @media (max-width: 991px){
            max-height: none;
        }
    }
}

.coaching-show-head-container{
    flex-grow: 1;
    padding: 28px 20px 28px 6.5vw;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @media (max-width: 991px){
        max-width: 760px;
        padding: 0 20px 30px 20px;
    }
    @media (max-width: 767px){
        padding: 20px 20px 0 20px;
    }
}
.coaching-show-head-content{

    @media (min-width: 1400px){
        min-width: 470px;
    }
    .icon-live{
        color: #000000;
    }
    h1{
        font-size: 20px;
        line-height: 1.4;
        margin-bottom: 10px;
        margin-top: 10px;

        @media (max-width: 1199px){
            font-size: 18px;
        }
    }
}
.coaching-show-speaker{
    display: flex;
    align-items: center;
    margin-bottom: 35px;
    padding-top: 3px;

    @media (max-width: 767px){
        flex-wrap: wrap;
        margin-bottom: 25px;
    }
    p{
        font-size: 27px;
        margin-bottom: 0;

        @media (max-width: 767px){
            font-size: 22px;
        }
    }
    del{
        font-size: 16px;
        opacity: 0.62;
        margin-left: 7px;
        margin-right: 5px;
    }
    &.mb-0{
        margin-bottom: 10px;
    }
}
.coaching-show-container{
    max-width: 945px;
    margin:  0 auto;
    padding: 60px 15px;

    @media (max-width: 767px){
        padding: 40px 15px;
    }
    h2{
        font-size: 20px;
        margin-bottom: 40px;
    }
    p{
        margin-bottom: 30px;
        line-height: 1.7;
    }
}

.coaching-courses-included{
    background-color: #F0F0F0;;
    padding: 60px 15px 60px;

    @media (max-width: 767px){
        padding: 40px 15px;
    }
}
.coaching-accordion{
    max-width: 915px;
    margin: 0 auto;
    padding: 0;
    background-color: transparent;

    > h3{
        font-size: 20px;
        margin-bottom: 40px;

        @media (max-width: 767px){
            margin-bottom: 30px;
        }
        strong{
            font-weight: 600;
        }
    }
}
.coaching-accordion-item{
    border-radius: 4px;
    margin-top: 10px;
    background-color: #ffffff;
}
.coaching-accordion-item-header{

    .btn{
        flex-direction: column;
        letter-spacing: 0;
        padding: 15px 50px 15px 20px;
        color: black;
        line-height: 1.4;
        position: relative;
        text-align: left;

        &:after {
            content: "";
            width: 22px;
            height: 22px;
            background-size: 100%;
            display: inline-block;
            position: absolute;
            right: 15px;
            top: 50%;
            margin-top: -11px;
            background-repeat: no-repeat;
        }

        &[aria-expanded="true"]:after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='Layer_1' data-name='Layer 1' viewBox='0 0 23 23'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23244199;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eminus%3C/title%3E%3Cg id='Group_8042' data-name='Group 8042'%3E%3Cg id='Group_8043' data-name='Group 8043'%3E%3Cg id='add'%3E%3Cg id='Group_8040' data-name='Group 8040'%3E%3Cg id='Group_8039' data-name='Group 8039'%3E%3Cpath id='Path_8670' data-name='Path 8670' class='cls-1' d='M11.5,0A11.5,11.5,0,1,0,23,11.5,11.5,11.5,0,0,0,11.5,0Zm0,22.05A10.55,10.55,0,1,1,22.05,11.5,10.55,10.55,0,0,1,11.5,22.05Z' transform='translate(0 0)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3Cpath id='Path_8672' data-name='Path 8672' class='cls-1' d='M6,11.5H17a.5.5,0,0,1,.5.5.5.5,0,0,1-.5.5H6a.5.5,0,0,1-.5-.5A.51.51,0,0,1,6,11.5Z' transform='translate(0 0)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        }

        &[aria-expanded="false"]:after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23.008' height='23.008' viewBox='0 0 23.008 23.008'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23767676;%7D%3C/style%3E%3C/defs%3E%3Cg transform='translate(174.961 -91.039)'%3E%3Cg transform='translate(-174.961 91.039)'%3E%3Cpath class='a' d='M11.5,0a11.5,11.5,0,1,0,11.5,11.5A11.517,11.517,0,0,0,11.5,0Zm0,22.049A10.545,10.545,0,1,1,22.049,11.5,10.557,10.557,0,0,1,11.5,22.049Z'/%3E%3Cpath class='a' d='M119.608,112.9h-5.752v-5.752a.479.479,0,1,0-.959,0V112.9h-5.752a.479.479,0,0,0,0,.959H112.9v5.752a.479.479,0,0,0,.959,0v-5.752h5.752a.479.479,0,0,0,0-.959Z' transform='translate(-101.873 -101.873)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        }

        &:focus {
            box-shadow: none;
        }

        @media (max-width: 767px) {
            padding: 10px 45px 10px 10px;
        }
        strong{
            font-weight: 600;
            display: inline-block;
        }
    }
}
.coaching-accordion-body{
    padding: 10px 20px 16px 20px;
    background-color: #ffffff;

    @media (max-width: 767px){
        padding: 10px 10px 10px 10px;

        p{
            font-size: 14px;
        }
    }
}
.coaching-page-spekars{
    max-width: 945px;
    padding: 60px 15px 60px;
    margin: 0 auto;

    @media (max-width: 767px){
        padding: 40px 15px;
    }
    h2{
        font-size: 20px;
        margin-bottom: 40px;
    }
    strong{
        font-weight: 600;
    }
}
.coaching-spekars{

    figure{
        margin-bottom: 30px;

        @media (max-width: 575px) {
            flex-direction: column;
            margin-bottom: 40px;
        }
        > img{
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;
        }
        figcaption{
            width: calc(100% - 100px);
            padding-left: 32px;

            @media (max-width: 575px){
                width: 100%;
                padding-left: 0;
                margin-top: 15px;
                text-align: center;
            }
            h3{
                font-size: 16px;
                margin-bottom: 11px;
            }
            p{
                line-height: 1.75;
                margin-bottom: 0;

                @media (max-width: 575px){
                    text-align: left;
                    line-height: 1.5;
                }
            }
        }
    }
}
