
.home-h2{
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 1em;
    text-align: center;

    @media (max-width: 1159px){
        font-size: 30px;
    }
    @media (max-width: 991px){
        font-size: 26px;
        margin-bottom: 1em;
    }
    @media (max-width: 767px){
        font-size: 24px;
    }
    &:lang(hy){
        font-size: 32px;

        @media (max-width: 1159px){
            font-size: 24px;
        }
    }
}

.theme-17-1{
    background: #19181E;
    color: #ffffff;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    padding-right: 24px;

    @media (max-width: 767px){
        padding-right: 0;
    }
}
.theme-17-1-left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: self-start;
    width: 42%;
    max-width: 468px;
    margin-left: auto;
    padding: 24px 0 24px 24px;
    margin-right: -2%;

    @media (max-width: 1159px){
        max-width: 400px;
    }
    @media (max-width: 991px){
        margin-right: -4%;
    }
    @media (min-width: 1500px) {
        max-width: 560px;
    }
    @media (max-width: 767px){
        width: 100%;
        max-width: inherit;
        position: relative;
        z-index: 1;
        margin-right: 0;
        padding: 48px 24px;
    }
    h1{
        font-weight: 700;
        font-size: 48px;
        margin-bottom: 0.32em;
        text-transform: uppercase;

        @media (min-width: 1500px) {
            font-size: 56px;
        }
        @media (max-width: 1159px){
            font-size: 40px;
        }
        @media (max-width: 991px){
            font-size: 30px;
        }
        @media (max-width: 767px){
            font-size: 8.4vw;
            line-height: 1.3;
        }
        &:lang(hy){
            font-size: 40px;
            line-height: 1.3;

            @media (max-width: 1159px){
                font-size: 34px;
            }
            @media (max-width: 991px){
                font-size: 24px;
            }
            @media (max-width: 767px){
                font-size: 6.4vw;
            }
        }
    }
    p{
        margin-bottom: 3.4em;
        line-height: 1.3;
        font-size: 20px;

        @media (min-width: 1500px){
            font-size: 22px;
        }
        @media (max-width: 1159px){
            margin-bottom: 2em;
            font-size: 16px;
        }
        @media (max-width: 767px){
            font-size: max(3.3vw, 15px);
            margin-bottom: 2.2em;
        }
    }
}
.theme-17-1-right{
    width: 60%;
    position: relative;
    overflow: hidden;
    padding: 0 8.65%;
    height: 40vw;

    @media (max-width: 767px) {
        opacity: .5;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 90vw;
        padding: 0;
    }
    img{
        width: 100%!important;
        clip-path: polygon(15% 0, 100% 0, 85% 100%, 0% 100%);
        height: 100%!important;
        object-fit: cover;

        @media (max-width: 767px){
            height: 100%;
            object-fit: cover;
        }
    }
    &:after, &:before{
        content: "";
        background: rgb(33 31 38);
        position: absolute;
        top: -7%;
        width: 13%;
        height: 131%;
        transform: rotate(17.6deg);
        z-index: 1;

        @media (max-width: 767px){
            width: 27%;
        }
    }
    &:after{
        left: 10%;

        @media (max-width: 767px){
            left: -15%;
        }
    }
    &:before{
        right: 13.5%;

        @media (max-width: 767px){
            right: -10.5%;
        }
    }
}
.th-17-2{
    background: #19181E;
    color: #ffffff;
    display: flex;
    align-items: center;
    position: relative;
}
.th-17-2-img{
    width: 40%;
    position: relative;
    overflow: hidden;
    padding-right: 7.65%;
    height: 38.65vw;

    @media (max-width: 767px){
        opacity: 0.3;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        padding: 0;
        height: 100%;
    }
    img{
        clip-path: polygon(0 0, 100% 0, 57% 100%, 0% 100%);
        width: 100%!important;
        height: 100%!important;
        object-fit: cover;
    }
    &:before{
        content: "";
        background: #211f26;
        position: absolute;
        top: -7%;
        width: 19%;
        height: 131%;
        right: 21.5%;
        transform: rotate(19.6deg);
        z-index: 1;

        @media (max-width: 767px){
            top: -10%;
            width: 47%;
            right: -15.5%;
            transform: rotate(22.6deg);
        }
    }
}
.th-17-2-text{
    width: 70%;
    margin-left: -10%;
    position: relative;
    z-index: 1;
    max-width: 1200px;
    padding: 24px 0;

    @media (max-width: 991px){
        width: 75%;
        margin-left: -17%;
    }
    @media (max-width: 767px){
        width: 100%;
        margin-left: 0;
        padding: 48px 24px;
    }
    .home-h2{
        margin-bottom: 2em;

        @media (max-width: 1199px){
            margin-bottom: 1em;
        }
        @media (max-width: 991px){
            margin-bottom: 0.7em;
            padding-left: 8%;
        }
        @media (max-width: 767px){
            margin-bottom: 0;
            padding-left: 0;
        }
    }
}
.th-17-2-container{
    display: flex;
    align-items: center;
    padding: 12px 0;

    @media (max-width: 767px){
        flex-wrap: wrap;
        width: 100%;
    }
}
.th-17-2-item{
    width: 33.33%;
    padding: 12px;
    text-align: center;

    @media (max-width: 767px){
        margin-top: 24px;
        flex-grow: 1;
        width: 100%;
        padding: 6px;
    }
    img{
        margin-bottom: 34px;
        width: 66px!important;
        height: 66px!important;

        @media (max-width: 1199px){
            margin-bottom: 24px;
            height: 50px!important;
        }
        @media (max-width: 991px){
            margin-bottom: 20px;
            height: 45px!important;
        }
        @media (max-width: 767px){
            margin-bottom: 15px;
            height: 50px!important;
        }
    }
    h3{
        font-weight: 700;
        font-size: 22px;
        margin-bottom: 12px;

        @media (max-width: 1199px){
            font-size: 20px;
        }
        @media (max-width: 991px){
            font-size: 18px;
            margin-bottom: 8px;
        }
    }
    p{
        font-weight: 300;
        font-size: 20px;
        margin-bottom: 0;

        @media (max-width: 1199px){
            font-size: 18px;
        }
        @media (max-width: 991px){
            font-size: 14px;
        }
    }
}
.theme-17-3{
    background: #201F26;
    color: #ffffff;
    padding: 60px 0;

    @media (max-width: 767px){
        padding: 48px 0;
    }
}
.theme-17-4{
    display: flex;
    flex-wrap: wrap;
    background: #F8F7F5;
    justify-content: space-between;
    position: relative;
}
.theme-17-4-left{
    width: 47%;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;

    @media (max-width: 767px){
        width: 100%;
        padding: 48px 24px;
    }
}
.theme-17-4-left-content{
    max-width: 325px;

    @media (max-width: 1199px) {
        margin-top: 0;
        margin-bottom: 0;
    }

    i{
        font-weight: 500;
        font-size: 24px;
        margin-bottom: 24px;
        display: inline-block;

        @media (max-width: 1199px){
            font-size: 22px;
        }
        @media (max-width: 991px){
            font-size: 20px;
            margin-bottom: 10px;
        }
    }
    h2{
        font-weight: 600;
        font-size: 35px;
        margin-bottom: 13px;

        @media (max-width: 1199px){
            font-size: 30px;
        }
        @media (max-width: 991px){
            font-size: 24px;
            margin-bottom: 10px;
        }
    }
    p{
        font-size: 20px;
        margin-bottom: 33px;

        @media (max-width: 1199px){
            font-size: 18px;
            margin-bottom: 24px;
        }
        @media (max-width: 991px){
            font-size: 15px;
            margin-bottom: 20px;
        }
    }
    .btn{

        @media (max-width: 991px){
            font-size: 14px;
        }
    }
}
.theme-17-4-right{
    width: 52.1%;

    @media (max-width: 767px){
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 0;
    }

    img{
        clip-path: polygon(25% 0, 100% 0, 100% 100%, 0% 100%);
        height: 100%!important;
        width: 100%!important;

        @media (max-width: 767px){
            object-fit: contain;
            opacity: 0.2;
        }
    }
}
.theme-17-5{
    padding: 60px 0;

    @media (max-width: 767px){
        padding: 48px 0 24px;
    }
}
.theme-17-5-title{
    display: flex;
    align-items: center;
    margin-bottom: 2em;

    @media (max-width: 767px){
        margin-bottom: 12px;
    }
    img{
        margin-right: 20px;

        @media (max-width: 767px){
            margin-right: 10px;
            width: 50px;
        }
    }
    .home-h2{
        margin-bottom: 0;
    }
}
.theme-17-5-container{
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: -10px;
    margin-right: -10px;

    @media (max-width: 767px){
        max-width: 350px;
        margin: 0 auto;
    }
}
.theme-17-5-item{
    width: 33.28%;
    padding: 10px;

    @media (max-width: 767px){
        width: 100%;
        padding: 12px 0;
        margin-bottom: 24px;
    }
    > img{
        width: 100%;
        height: 300px;
        object-fit: cover;
        margin-bottom: 20px;

        @media (max-width: 1159px) {
            height: 193px;
        }
        @media (max-width: 767px){
            height: 300px;
        }
    }
    p{
        color: #555557;

        @media (max-width: 1159px) {
            font-size: 14px;
        }
    }
    h3{
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 3px;
    }
    h4{
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 0;
    }
}
.yellow-block{
    background-color: var(--color-1);
    height: 33px;
}


.theme-all-form-17.theme-all-form{
    background: #201F26;
}
.theme-all-form .theme-all-form-bg{
    opacity: .1;
}
.theme-faq{
    background-color: #201F26;
    color: #ffffff;
    padding: 72px 0;

    @media (max-width: 767px){
        padding: 48px 0;
    }
}
